import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-dialog-crude',
  templateUrl: './dialog-crude.component.html'
})
export class DialogCrudeComponent {
  @Input() cancelText: string = 'CANCELAR';
  @Input() confirmText: string = 'CONFIRMAR';
  @Input() confirmDisabled: boolean;

  @Output() onCancel = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter<void>();
}
