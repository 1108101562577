<button
  class="subitem__button"
  routerLinkActive="subitem__button--selected"
  [routerLink]="subItem.routes | routerBuilder: companyId"
  [attr.aria-label]="subItem.title"
  [ngClass]="{
    'subitem__button--restricted': subItem.restricted
  }"
  (click)="sidebarService.selectSubItem()"
>
  {{subItem.title}}
</button>
