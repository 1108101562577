import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {takeUntil} from 'rxjs/operators';

import * as Notification from '../../shared/model/notification';
import {BaseClass} from '../../core/base-class';

import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})

export class NotificationPopupComponent extends BaseClass implements OnInit {
  public _visibility: boolean;
  public notification: Notification.Interface;

  @Input() set visibility (visibility: boolean) {
    this._visibility = visibility;
  }

  @Output() openNotifications = new EventEmitter();

  constructor (private notificationService: NotificationService) {
    super();
  }

  ngOnInit () {
    this.notificationService.notifications$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((notifications: Notification.Interface[]) => {
        let amount = 0;

        notifications.forEach((notification: Notification.Interface) => {
          amount += notification.errors;
        });

        this.notification = {
          id: '',
          errors: amount,
          type: Notification.TypeEnum.critical,
          created_at: notifications?.[0]?.created_at
        };
      });
  }

  public notificationsSelected (): void {
    this._visibility = false;
    this.openNotifications.emit();
  }
}
