import {Component, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';

import * as Notification from '../../shared/model/notification';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent {
  @Output() close = new EventEmitter();

  constructor(
    public notificationService: NotificationService,
    private router: Router,
  ) {}

  openNotificationDetails(notification: Notification.Interface) {
    this.close.emit();
    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(()=> this.router.navigateByUrl(`c/default/documents/sick_notes/${notification.route}`));
  }
}
