import { Injectable } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titleSubj = new BehaviorSubject<string>('');
  constructor(private titleService: Title) { }

  public get title$(): Observable<string> {
    return this.titleSubj.asObservable();
  }

  public setTitle(title: string): void {
    this.titleSubj.next(title);
    this.titleService.setTitle(title + ' - Closecare');
  }

  public getTitle(): string {
    return this.titleSubj.getValue();
  }

  public getCompleteTitle(): string {
    return this.titleService.getTitle();
  }

  getTitleService(): Title {
    return this.titleService;
  }
}
