import {Component, OnDestroy} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

import {ReplaySubject, Subscription} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import * as Roles from '../../core/roles';
import {SessionService} from '../../core/session.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {
  permissions: string[];
  loading = true;
  cards: ReplaySubject<any[]> = new ReplaySubject(1);
  subscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver,
              private session: SessionService) {
    /** Based on the screen size, switch from standard to one column per row */
    this.subscription = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      mergeMap(({ matches }) => {
        let cols = 1;
        if (matches) {
          cols = 2;
        }
        return this.session.permissions$.pipe(
          map(p => {
            const tiles = [];
            if (!!p.permissions[Roles.PERMISSIONS.dashboard_r]) {
              tiles.push({ title: 'Métricas', link: './dashboard', image: 'assets/img/undraw_all_the_data.svg', cols: cols, rows: 1});
            }
            if (!!p.permissions[Roles.PERMISSIONS.sick_note_r]) {
              tiles.push({title: 'Atestados', link: './documents/sick_notes/', image: 'assets/img/undraw_file_manager.svg', cols: cols, rows: 1});
            }
            if (!!p.permissions[Roles.PERMISSIONS.ticket_r]) {
              tiles.push({title: 'Ações', link: './tickets', image: 'assets/img/undraw_light_the_fire.svg', cols: cols, rows: 1});
            }
            return tiles;
          })
        );
      })
    ).subscribe(tiles => {
      this.cards.next(tiles);
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
