<div class="form-situations">
  <header
    *ngIf="!hideDates"
    class="mb-2 form-situations__header"
    [ngClass]="{ 'form-situations__disabled': form.disabled }"
  >
    <span
      class="mr-3 mb-2"
      *ngIf="initialDate"
    >
      <strong>Data inicial:</strong>
      <span class="text-nowrap">
        {{ initialDate | date: dateFormat:'+00:00' }}
      </span>
    </span>
    <span
      class="mr-3 mb-2"
      *ngIf="endDate"
    >
      <strong>Data de retorno:</strong>
      <span class="text-nowrap">
        {{ endDate | date: dateFormat:'+00:00' }}
      </span>
    </span>
    <span
      class="mb-2"
      *ngIf="type === eTimeType.hour"
    >
      <strong>Duração:</strong>
      <span class="text-nowrap">
        {{ initialDate | differenceInTime: endDate }}
      </span>
    </span>
  </header>
  <form
    class="small-form-field form-situations"
    [formGroup]="form"
  >
    <div *ngIf="$any(situationsArray.controls) as situationsArrayControls">
      <div
        class="row"
        formArrayName="situations"
        *ngFor="let situationControl of situationsArrayControls; let i = index"
      >
        <mat-form-field
          class="col-12 col-sm-6"
          appearance="outline"
        >
          <mat-label>Situação</mat-label>
          <mat-select
            [formControl]="situationControl.controls.situation"
            [compareWith]="compareSituations"
            [required]="
              required ||
              !!situationControl.controls.duration.value
            "
          >
            <mat-option
              value="loader"
              *ngIf="loader$ | async"
            >
              <mat-progress-spinner
                class="mx-auto"
                mode="indeterminate"
                color="primary"
                diameter="24"
              ></mat-progress-spinner>
            </mat-option>
            <mat-option *ngIf="!dataSource?.length">
              Nenhuma
            </mat-option>
            <mat-option
              class="multiline-option"
              *ngFor="let situation of dataSource"
              [value]="situation"
              [disabled]="situation?.granted_days_required && type === eTimeType.hour"
            >{{ situation?.name || situation?.code }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
          [ngClass]="{
            'col-12 col-sm-6': i === 0 && (
              type === eTimeType.hour || (
                type === eTimeType.day &&
                !situationControl.controls.situation.value?.granted_days_required
              )
            ),
            'col-10 col-sm-4 col-lg-5': i > 0 && (
              type === eTimeType.hour || (
                type === eTimeType.day &&
                !situationControl.controls.situation.value?.granted_days_required
              )
            ),
            'col-6 col-sm-3': i === 0 && (
              type === eTimeType.day &&
              situationControl.controls.situation.value?.granted_days_required
            ),
            'col-6 col-sm-2 col-lg-3': i > 0 && (
              type === eTimeType.day &&
              situationControl.controls.situation.value?.granted_days_required
            ),
            'form-situations__disabled': (
              i > 0 ||
              situationsArrayControls.length === 1
            ) && !form.disabled
          }"
        >
          <mat-form-field
            class="w-100"
            appearance="outline"
            *ngIf="type === eTimeType.day; else timePicker"
          >
            <mat-label>Qtd de dias</mat-label>
            <input
              matInput
              type="number"
              min="1"
              autocomplete="off"
              name="new-num_days"
              [max]="maxDays"
              [readonly]="i > 0"
              [formControl]="situationControl.controls.duration"
            >
          </mat-form-field>
          <ng-template #timePicker>
            <mat-form-field
              class="w-100"
              appearance="outline"
            >
              <mat-label>Duração (horas:minutos)</mat-label>
              <input
                matInput
                type="time"
                step="60"
                autocomplete="off"
                name="new-time-picker"
                [max]="maxTime"
                [readonly]="situationsArray.controls.length === 1"
                [formControl]="situationControl.controls.duration"
              >
            </mat-form-field>
          </ng-template>
        </div>
        <mat-form-field
          class="col-{{ i === 0 ? '6' : '4'}} col-sm-{{ i === 0 ? '3' : '2'}}"
          appearance="outline"
          matTooltip="Quantos dias devem ser pagos pela empresa, no caso de um afastamento pelo INSS, por ex."
          *ngIf="
            type === eTimeType.day &&
            situationControl.controls.situation.value?.granted_days_required
          "
        >
          <mat-label>Dias justificados</mat-label>
          <input
            matInput
            type="number"
            min="0"
            autocomplete="off"
            name="new-num_days"
            [max]="situationControl.controls.duration?.value"
            [formControl]="situationControl.controls.granted_days"
          >
        </mat-form-field>
        <div
          class="col-2 col-sm-1"
          *ngIf="i > 0"
        >
          <button
            mat-icon-button
            class="form-situations__remove"
            color="warn"
            (click)="situationsArray.removeAt(i)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="w-100 d-flex align-items-start">
        <button
          mat-button
          color="primary"
          class="button-like-link"
          *ngIf="multiples && situationsArrayControls.length < 2 && addReleased"
          [disabled]="form.disabled"
          (click)="addSituation()"
        >Adicionar situação</button>
      </div>
    </div>
  </form>
</div>
