<div class="metabase">
  <ng-container *ngIf="safeMetabaseUrl && !reloading">
    <iframe id='dashboardIframe'
            [src]="safeMetabaseUrl"
            frameborder="0"
            allowtransparency
            onload="iFrameResize({checkOrigin: false, bodyPadding: '0 16px', scrolling: true}, this);">
    </iframe>
  </ng-container>

  <div *ngIf="loading; else locationPicker">
    <mat-spinner color="primary"
                 diameter="32"
                 style="position: absolute; top: 0; right: 50%; margin-left: auto; margin-top: 40px">
    </mat-spinner>
  </div>
  <ng-template #locationPicker>
    <mat-form-field *ngIf="dashboard?.filter == 'location'" class="location-picker" appearance="outline" color="primary">
      <mat-label>Filtrar Unidade</mat-label>
      <mat-select [(value)]="selectedLocation" (selectionChange)="locationChanged($event.value)"
                  panelClass="location-picker-option">
        <mat-option>Todas unidades</mat-option>
        <mat-option *ngFor="let location of (locations | async)?.locations" [value]="location">
          {{location.name || location.code}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>

  <mat-spinner *ngIf="reloading"
               color="primary"
               diameter="32"
               style="margin-left: auto; margin-right: auto; margin-top: 200px">
  </mat-spinner>
</div>
