<div class="category-select">
  <section>
    <p
      class="category-select__label"
      [ngClass]="{
        'category-select__label--no-margin-top': noMarginTop
      }"
    >{{labelSelect}}</p>
    <app-pills
      [pills]="pills"
      (changed)="pillsChanged($event[0])"
    ></app-pills>
    <div
      class="pills category-select__subpills"
      *ngIf="selectedCategory"
    >
      <button
        mat-stroked-button
        color="primary"
        class="pills__pill"
        *ngFor="let subCategory of selectedCategory.children"
        (click)="selectSubCategory(subCategory)"
      >
        <mat-icon *ngIf="subCategorySelected(subCategory.id)">
          check
        </mat-icon>
        {{subCategory.name}}
      </button>
    </div>
  </section>
  <section
    class="category-select__section category-select__selected"
    *ngIf="selectedCategory && selectedSubCategories?.length"
  >
    <p class="category-select__label">{{labelSelected}}</p>
    <mat-chip-list>
      <mat-chip
        *ngFor="let selectedSubCategory of selectedSubCategories"
        (removed)="selectSubCategory(selectedSubCategory)"
      >
        {{selectedSubCategory.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </section>
</div>
