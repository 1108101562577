import {
  Component,
  OnInit
} from '@angular/core';
import { Location } from '@angular/common';

import { CompanyService } from '../services/company.service';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { Company } from '../shared/model/company';
import { SessionService } from '../core/session.service';

@Component({
  selector: 'app-company-picker',
  templateUrl: './company-picker.component.html',
  styleUrls: ['./company-picker.component.scss']
})
export class CompanyPickerComponent implements OnInit {
  loading = true;

  private returnUrl: string;
  private regex = /c\/([\w-]+)/;

  constructor(
    public session: SessionService,
    public companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/c/default';
    this.companyService.companies$.subscribe(() => this.loading = false);
  }

  chooseCompany(company: Company) {
    this.loading = true;
    this.session.changeCurrentCompany(company.id)
      .subscribe(
        (success) => {
          const url = this.returnUrl.replace(this.regex, `c/${company.id}`);
          this.router.navigateByUrl(url);
        },
        (error) => {
          this.loading = false;
        });
  }

  goBack() {
    this.loading = false;
    this.location.back();
  }

}
