import {NgModule} from '@angular/core';

import {MenuModule} from './menu/menu.module';
import {SubmenuModule} from './submenu/submenu.module';

@NgModule({
  imports: [
    MenuModule,
    SubmenuModule
  ],
  exports: [
    MenuModule,
    SubmenuModule
  ]
})
export class SidebarModule {}
