import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Sort} from '@angular/material/sort';

import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {catchError, filter, map, mergeMap, retry, shareReplay, take, tap} from 'rxjs/operators';
import {formatISO} from 'date-fns';
import moment from 'moment';

import {
  API_LOG_SYNC,
  API_PROFESSIONALS,
  API_REASONS_MESSAGES,
  ICD_SEARCH_API,
  RAIS_REASONS,
  ApiService,
  ListAPIResponse,
  SICK_NOTE_API_URL,
  SICK_NOTE_API_URL_V5,
  SICK_NOTE_TYPES_LIST,
  SITUATIONS_API_URL,
  VERIFY_SN_API_URL,
  VACCINE_TYPES,
  WARNINGS_API_URL,
} from '../core/api.service';
import {SessionService} from '../core/session.service';
import {PERMISSIONS} from '../core/roles';
import { IResponseItems } from 'src/app/shared/interfaces/response.interfaces';
import * as File from '../shared/model/file.model';
import {Filter} from '../shared/model/filter';
import { Icd } from '../shared/model/icd';
import {ProfessionalDetailsIf} from '../shared/model/professional';
import {
  IExtendedSickNote,
  FieldDateType,
  PageItem,
  pendingValidation,
  RaisReason,
  SickNoteBody,
  SickNoteChange,
  SickNoteListRequest,
  SickNoteStatusType,
  SickNotesAPIResponse,
  SickNotesTypesAPIResponse,
  SickNotesV5APIResponse,
  Situation,
  VaccineType,
  WarningsAPIResponse,
  SickNoteType,
  SickNoteSync
} from '../shared/model/sick_note';
import {Reason} from '../shared/model/refusal-reasons.model';
import {Warning} from '../shared/model/warning';
import {pollWhile} from '../shared/utils/pooling';
import {Utils} from '../shared/utils/utils';
import {setDateWithoutTimezone} from '../shared/utils/time';

import {DialogReprovationComponent, DialogReprovationResult} from '../components/dialog-reprovation/dialog-reprovation.component';

import {UserService} from './user.service';

import {
  ValidateDialogComponent,
  ValidateDialogResult
} from '../pages/sick-note/validate-dialog/validate-dialog.component';
import { ISituation } from 'src/app/components/form-components/entities/form-components.interfaces';
import {PaginationDirection} from '../components/paginator/paginator.component';
import {SimpleDialogComponent} from '../components/simple-dialog/simple-dialog.component';
import {User} from '../shared/model/user';

export interface TabStatusCounts {
  digitize: number;
  unverified: number;
  pending: number;
  valid: number;
  invalid: number;
}

@Injectable({
  providedIn: 'root'
})
export class SickNoteService {
  registerSeveral = false;
  private formTouched$ = new BehaviorSubject(false);

  private _sickNoteChanges$ = new Subject<SickNoteChange>();
  private sickNoteTypes$: Observable<SickNoteType[]>;

  constructor(
    private session: SessionService,
    private userService: UserService,
    private api: ApiService
  ) {
  }

  /**
   * Listen to sicknote changes
   * @param actions filter by a list of actions that you want listen to
   */
  getSickNoteChanges$(actions?: string[]): Observable<SickNoteChange> {
    return this._sickNoteChanges$.asObservable()
      .pipe(filter((c) => !actions || actions.includes(c.action)));
  }

  public getSickNotesCount(): Observable<TabStatusCounts> {
    const verified = {filters: {verified: true}},
     allCounts = this.api.get<{ [key in SickNoteStatusType]: number }>(`${SICK_NOTE_API_URL(this.session.currentCompanyId)}/status`),
     verifiedCounts = this.api.post<{ [key in SickNoteStatusType]: number }>(`${SICK_NOTE_API_URL(this.session.currentCompanyId)}/status`, verified);
    return forkJoin([allCounts, verifiedCounts])
      .pipe(
        map(([all, verif]) => {
          const verifiedPending = this.safeNumber(verif.filled) + this.safeNumber(verif.verified)
            + this.safeNumber(verif.warning) + this.safeNumber(verif.triage),
           digitize = this.safeNumber(all.pending),
           unverified = this.safeNumber(all.filled) + this.safeNumber(all.verified)
            + this.safeNumber(all.warning) + this.safeNumber(all.triage) - this.safeNumber(verifiedPending),
           pending = this.safeNumber(verifiedPending),
           valid = this.safeNumber(all.valid),
           invalid = this.safeNumber(all.invalid);
          return {digitize, unverified, pending, valid, invalid};
        })
      );
  }

  public saveSickNote(
    sickNoteForm: SickNoteBody,
    companyId: string,
    sick_note_id?: string,
    status?: 'valid' | 'invalid' | 'none',
    loadFiles?: File.Data[],
    fullMode?: boolean,
    overlappedApproved = false
  ): Observable<string> {
    let obs: Observable<any>;
    const formData = new FormData();

    if (!sickNoteForm?.files?.length) {
      formData.set('file', '');
    } else {
      sickNoteForm?.files?.forEach((fileForm: File.Data | File.Loaded) => {
        formData.append('file', loadFiles?.find((data: File.Data) => (
          data.signed_url === (fileForm as File.Data)?.signed_url
        ))?.id || (fileForm as File.Loaded).inputed);
      });
    }

    if (!!status && status !== 'none') {
      formData.set('status', status);
    }

    formData.set('person_id', sickNoteForm.person_id || '');
    formData.set('type_id', '' + sickNoteForm.type_id || '');

    if (!fullMode) {
      formData.set('appointment_date', formatISO(sickNoteForm.dynamicAppointmentDate) || '');

      formData.set('status', 'pending');
      formData.forEach((value, key) => {
        if (value === '' || value === null || value === undefined) {
          formData.delete(key);
        }
      });
    } else {
      const { initialDate, endDate } = sickNoteForm.dynamicInterval;
      formData.set('appointment_date', formatISO(initialDate) || '');
      formData.set('expiration_date', formatISO(endDate) || '');

      const public_notes = sickNoteForm.public_notes || { message: '', reasons: [] };
      formData.set('public_notes', JSON.stringify(public_notes));

      const { dynamicProfessional } = sickNoteForm;

      formData.set('professional_id', !!dynamicProfessional?.code ? dynamicProfessional.code.toString() : '');
      formData.set('professional_type', dynamicProfessional?.type || '');
      formData.set('professional_state', dynamicProfessional?.state || '');
      formData.set('professional_name', dynamicProfessional?.name || '');

      formData.set('premises', sickNoteForm.premises || '');
      formData.set('internal_notes', sickNoteForm.internal_notes || '');
      formData.set('link', sickNoteForm.link || '');
      formData.set('secret', sickNoteForm.secret || '');

      if (!!sickNoteForm.vaccine_name && sickNoteForm.vaccine_dose) {
        formData.set('vaccine_name', sickNoteForm.vaccine_name);
        formData.set('vaccine_dose', '' + sickNoteForm.vaccine_dose);
      }
      // Clear empty issues
      Object.entries(sickNoteForm.issues).forEach(([k, v]) => {
        if (v) {
          sickNoteForm.issues[k] = 'erasure';
        } else {
          delete sickNoteForm.issues[k];
        }
      });
      formData.set('issues', JSON.stringify(sickNoteForm.issues));

      if (sickNoteForm.icds?.length) {
        sickNoteForm.icds.forEach(i => formData.append('icds', i));
      } else {
        formData.append('icds', '');
      }

      if (sickNoteForm.dynamicSituations?.length) {
        sickNoteForm.dynamicSituations.forEach(({
          id, start_date, end_date, granted_days
        }) => formData.append('situations', JSON.stringify(
          { id, start_date, end_date, granted_days }
        )));
      } else {
        formData.append('situations', '');
      }

      if (sickNoteForm.reason_ids?.length) {
        sickNoteForm.reason_ids.forEach(i => formData.append('reason_ids', i?.toString()));
      }

      if (sickNoteForm.travel_minutes_before != null && sickNoteForm.travel_minutes_before >= 0) {
        formData.append('travel_minutes_before', String(sickNoteForm.travel_minutes_before));
      }

      if (sickNoteForm.travel_minutes_after != null &&  sickNoteForm.travel_minutes_after >= 0) {
        formData.append('travel_minutes_after', String(sickNoteForm.travel_minutes_after));
      }
    }

    // TODO
    // https://linear.app/closecare/issue/TB-387/desativar-sobreposicao-temporariamente
    // const params = new HttpParams()
    //   .set('force', overlappedApproved.toString());
    const params = new HttpParams()
      .set('force', true);

    if (sickNoteForm.person_id) {
      // if has ID, do update. Else add new.
      if (sick_note_id) {
        obs = this.api.patch<{id: string}>(`${SICK_NOTE_API_URL(companyId)}/${sick_note_id}`, formData, {params}).pipe(
          map(value => value.id)
        );
      } else {
        obs = this.api.post<{id: string}>(SICK_NOTE_API_URL(companyId), formData, {params}).pipe(
          map(value => value.id)
        );
      }
    } else {
      return new Observable((observer => {
        observer.error({error: 'Missing person_id'});
        observer.complete();
      }));
    }

    return obs;
  }

  private safeNumber(value: number) {
    return value || 0;
  }

  searchICD(query: string): Observable<Icd[]> {
    const params = new HttpParams()
      .set('query', query)
      .set('page', '1')
      .set('obj_per_page', '10');
    return this.api.get<{icds: Icd[]}>(ICD_SEARCH_API(this.session.currentCompanyId), {params}).pipe(
      map(value => value.icds),
      catchError(error => [])
    );
  }

  listSickNotes(pageSize: number,
    status?: string[],
    verified?: boolean,
    filters?: Filter[],
    firstItem?: PageItem,
    lastItem?: PageItem,
    direction?: PaginationDirection,
    sort?: Sort): Observable<SickNotesV5APIResponse> {
    const params: SickNoteListRequest = {
      obj_per_page: pageSize,
      filters: {},
      /** only paginate if has a direction */
      first_item: direction ? firstItem : undefined,
      last_item: direction ? lastItem : undefined,
      direction
    };
    params.sort = sort?.active ? [{field: sort?.active, order: sort?.direction}] : undefined;

    // Deal with status filter
    const filteredStatus = filters.filter(f => f.field === 'status'),
     intersectStatus = filteredStatus?.length ? status.filter(value => !!filteredStatus.find(f => f.value === value)) : status;
    if (!intersectStatus?.length) {
      return of(new SickNotesAPIResponse());
    }
    params.filters.status = intersectStatus;
    params.filters.verified = verified;

    if (filters) {
      filters.filter(f => f.field !== 'status').forEach((f) => {
        params.filters[f.field] = f.value;
      });
    }

    return this.api.post<SickNotesAPIResponse>(`${SICK_NOTE_API_URL_V5(this.session.currentCompanyId)}/list`, params).pipe(
      catchError(() => of(new SickNotesAPIResponse()))
    );
  }

  listSickNoteTypes(page = 1, pageSize = 100): Observable<SickNoteType[]> {
    if (!this.sickNoteTypes$) {
      const params = new HttpParams()
        .set('page', page.toString())
        .set('obj_per_page', pageSize.toString());
      this.sickNoteTypes$ = this.session.currentCompanyId$
        .pipe(
          mergeMap((companyId) => this.api.get<SickNotesTypesAPIResponse>(SICK_NOTE_TYPES_LIST(companyId), {params})),
          catchError(() => of(new SickNotesTypesAPIResponse())),
          map((response) => response.sick_notes_types
              ?.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { numeric: true }))),
          shareReplay(1)
        );
    }
    return this.sickNoteTypes$;
  }

  exportSickNotes(start_date: string,
    end_date: string,
    field_date: FieldDateType = 'appointment_date',
    format: 'csv' | 'xlsx' = 'xlsx',
    status: string[]): Observable<{type: string; url: string}> {
    let params = new HttpParams()
      .set('export', 'true')
      .set('format', format)
      .set('start_date', start_date)
      .set('end_date', end_date)
      .append('sync_status', 'error')
      .append('sync_status', 'success')
      .append('sync_status', 'not_sync')
      .set('field_date', field_date);

    status.forEach(s => {
      params = params.append('status', s);
    });
    return this.api.get<SickNotesAPIResponse>(SICK_NOTE_API_URL(this.session.currentCompanyId), {params}).pipe(
      // mergeMap((response) => combineLatest([of(response), this.http.get(response.file.url, {responseType: 'blob'})])),
      map((response) =>
        // return new Blob([response], {type: response.type});
         response?.file
      ),
      catchError((error) => of(null))
    );
  }

  getSickNoteV4(id: string): Observable<IExtendedSickNote> {
    return this.api.get<IExtendedSickNote>(`${SICK_NOTE_API_URL(this.session.currentCompanyId)}/${id}`);
  }

  isSickNoteProcessing(id: string, stop$: Observable<any>): Observable<{processing: boolean; status?: SickNoteStatusType}> {
    return this.api.get<{processing: boolean; status: SickNoteStatusType}>(`${SICK_NOTE_API_URL(this.session.currentCompanyId)}/${id}/process`)
      .pipe(
        catchError(e => of({processing: true, status: undefined})),
        pollWhile(2000, (r) => r?.processing, stop$, 10, true),
        tap((v) => {
          if (!v.processing && pendingValidation(v.status)) {
            this.addSickNoteChange({
              action: 'validate',
              sickNoteId: id,
              sickNoteStatus: v.status,
              auto: false
            });
          }
        }),
      );
  }

  private getSickNoteChangeFormData(
    status: SickNoteStatusType, changes: Partial<SickNoteBody>
  ): FormData {
    const formData = new FormData();

    if (['valid', 'invalid'].includes(status)) {
      formData.set('status', status);
    }
    if (!changes) {
      return formData;
    }

    if (
      !!changes.dynamicInterval?.initialDate &&
      !!changes.dynamicInterval?.endDate
    ) {
      const appointment = formatISO(changes.dynamicInterval?.initialDate),
       expiration = formatISO(changes.dynamicInterval?.endDate);

      formData.set('appointment_date', appointment);
      formData.set('expiration_date', expiration);
    }

    if (changes.icds) {
      changes.icds.forEach((icd: string) => formData.append('icds', icd));
    }

    if (changes.professional) {
      const {professional} = changes;

      formData.append('professional_id', professional.id.toString());
      formData.append('professional_name', professional.name);
      formData.append('professional_state', professional.state);
      formData.append('professional_type', professional.type);
    }

    if (changes.type_id) {
      formData.append('type_id', changes.type_id.toString());
    }

    if (changes.premises) {
      formData.append('premises', changes.premises);
    }

    if (changes.situations) {
      changes.situations?.forEach((s) => {
        const situation = {
          id: s.id,
          start_date: Utils.dateToStringWithOffset(moment.utc(s.start_date)),
          end_date: Utils.dateToStringWithOffset(moment.utc(s.end_date)),
          granted_days: s.granted_days
        };
        formData.append('situations', JSON.stringify(situation));
      });
    }

    if (changes.internal_notes) {
      formData.append('internal_notes', changes.internal_notes);
    }

    if (changes.travel_minutes_before >= 0) {
      formData.append('travel_minutes_before', String(changes.travel_minutes_before));
    }

    if (changes.travel_minutes_after >= 0) {
      formData.append('travel_minutes_after', String(changes.travel_minutes_after));
    }

    if (typeof changes.related === 'string') {
      formData.append('related', changes.related);
    }

    if (changes.vaccine_dose) {
      formData.append('vaccine_dose', String(changes.vaccine_dose));
    }

    if (changes.vaccine_name) {
      formData.append('vaccine_name', changes.vaccine_name);
    }

    if (changes?.reason_ids?.length) {
      changes.reason_ids?.forEach((reasonId) => {
        formData.append('reason_ids', reasonId.toString());
      });
    }
    // TODO finish fields
    return formData;
  }

  updateSickNote(
    sickNoteId: string,
    status: SickNoteStatusType,
    changes?: Partial<SickNoteBody>,
    overlappedApproved = false
  ): Observable<any> {
    const formData = this.getSickNoteChangeFormData(status, changes);
    // TODO
    // https://linear.app/closecare/issue/TB-387/desativar-sobreposicao-temporariamente
    // const params = new HttpParams()
    //   .set('force', overlappedApproved.toString());
    const params = new HttpParams()
      .set('force', true);

    return this.api.patch(
      `${SICK_NOTE_API_URL(this.session.currentCompanyId)}/${sickNoteId}`, formData, { params })
      .pipe(
        tap(() => {
          this.addSickNoteChange({
            action: 'update',
            sickNoteId,
            sickNoteStatus: status,
            auto: false
          });
        })
      );
  }

  validateSickNote(
    id: string,
    valid: boolean,
    message?: string,
    changes?: Partial<SickNoteBody>,
    overlappedApproved = false
  ): Observable<any> {
    const action = (valid ? 'approve' : 'reprove'),
    status = (valid ? 'valid' : 'invalid'),
    formData = this.getSickNoteChangeFormData(status, changes),
    publicNotes = {
      message,
      reasons: []
    },
    // TODO
    // https://linear.app/closecare/issue/TB-387/desativar-sobreposicao-temporariamente
    // params = new HttpParams()
    //   .set('force', overlappedApproved.toString());
    params = new HttpParams()
      .set('force', true);

    formData.set('public_notes', JSON.stringify(publicNotes));
    return this.api.patch(`${SICK_NOTE_API_URL(this.session.currentCompanyId)}/${id}/${action}`, formData, {params})
      .pipe(
        tap(() => {
          this.addSickNoteChange({
            action: 'validate',
            sickNoteId: id,
            sickNoteStatus: status,
            auto: false
          });
        })
      );
  }

  getSickNotes(ids: string[]): Observable<IExtendedSickNote[]> {
    const observables: Observable<IExtendedSickNote>[] = ids.map(id => this.getSickNoteV4(id).pipe(
        take(1),
        catchError(() => of(null))
      ));
    return forkJoin(observables).pipe(
      map(items => items.filter(item => !!item)
          .sort((a, b) => (new Date(b?.fields?.appointment_date).getTime() - new Date(a?.fields?.appointment_date).getTime())))
    );
  }

  getDoctorInfo(crm: number, uf: string): Observable<ProfessionalDetailsIf | null> {
    return this.api.get<ProfessionalDetailsIf>(API_PROFESSIONALS(crm, uf)).
      pipe(
        catchError((e) => of(null)),
      );
  }

  listWarnings(page: number, pageSize: number, has_ntep?: boolean, threshold?: number): Observable<WarningsAPIResponse> {
    const params = new HttpParams()
      .set('has_ntep', has_ntep ? has_ntep.toString() : '')
      .set('leave_days_threshold', threshold ? threshold.toString() : '1')
      .set('page', page.toString())
      .set('obj_per_page', pageSize.toString());

    return this.api.get<WarningsAPIResponse>(WARNINGS_API_URL(this.session.currentCompanyId), {params})
      .pipe(
        map(response => {
          response.warnings = response.warnings.map(obj => Object.assign(new Warning(), obj));
          return response;
        }));
  }

  showInvalidateDialog(dialog: MatDialog): MatDialogRef<DialogReprovationComponent, DialogReprovationResult> {
    return DialogReprovationComponent.open(dialog);
  }

  showValidateDialog(
    dialog: MatDialog,
    sickNote: IExtendedSickNote
  ): MatDialogRef<ValidateDialogComponent, ValidateDialogResult> {
    return ValidateDialogComponent.open(dialog, {
      interval: {
        initialDate: setDateWithoutTimezone(sickNote?.fields.appointment_date),
        endDate: setDateWithoutTimezone(sickNote?.fields.expiration_date)
      },
      message: sickNote?.fields?.public_notes?.message,
      typeId: sickNote?.fields.type_id,
      situations: sickNote?.extras.situations as ISituation[]
  });
  }

  /**
   * If status is provided, it must be allowed for the user role, besides the general validation permission
   */
  userCanValidate(sickNote?: IExtendedSickNote): boolean {
    const p = this.session.permissions;
    return !!this.userService.hasPermissions([PERMISSIONS.sick_note_v], p)
      && (!sickNote?.status || !p?.sick_note_status?.v?.length || p.sick_note_status.v.includes(sickNote?.status))
      && this.teamMatch(sickNote, this.session.companyUser);
  }

  userCanEdit(sickNote?: IExtendedSickNote): boolean {
    const p = this.session.permissions;
    return !!this.userService.hasPermissions([PERMISSIONS.sick_note_w], p)
      && (!sickNote?.status || !p?.sick_note_status?.w?.length || p.sick_note_status.w.includes(sickNote?.status))
      && this.teamMatch(sickNote, this.session.companyUser);
  }

  userCanCreate(): boolean {
    const p = this.session.permissions;
    return !!this.userService.hasPermissions([PERMISSIONS.sick_note_c], p);
  }

  private teamMatch(sickNote: IExtendedSickNote, user: User): boolean {
    const userTeamId = this.session.companyUser?.restrictions?.team?.id;
    return (!sickNote?.extras?.team?.id || !userTeamId || userTeamId === sickNote?.extras?.team?.id);
  }

  addSickNoteChange(change: SickNoteChange): void {
    this._sickNoteChanges$.next(change);
  }

  getSituations(typeId?: number): Observable<Situation[]> {
    let params = new HttpParams()
      .set('page', 1)
      .set('obj_per_page', 500);
    if (typeId) {
      params = params.set('type_id', typeId);
    }
    return this.api.get<ListAPIResponse<Situation>>(SITUATIONS_API_URL(this.session.currentCompanyId), {params})
      .pipe(
        catchError(() => of(new ListAPIResponse<Situation>())),
        map((r) => (r.items || []).sort((a, b) => a.code.localeCompare(b.code, 'en', { numeric: true })))
      );
  }

  getRaisReasons(): Observable<RaisReason[]> {
    return this.api.get<ListAPIResponse<RaisReason>>(RAIS_REASONS)
      .pipe(
        catchError(() => of(new ListAPIResponse<RaisReason>())),
        map((r) => r.items)
      );
  }

  getFileName (sickNote): string {
    let fileName = `${sickNote?.short_id}_`;
    fileName += `${
      moment(sickNote?.fields.appointment_date).format('DD-MM-YYYY')}_`;
    fileName += sickNote?.id;

    return fileName;
  }

  getReasons(reasonIds: number[]): Observable<Reason[]> {
    const reason_ids = reasonIds.map((id) => id.toString());
    return this.session.currentCompanyId$.pipe(
      mergeMap((companyId) => this.api.get<ListAPIResponse<Reason>>(
          API_REASONS_MESSAGES(companyId), {params: { reason_ids }}
        ).pipe(
          retry(2),
          map(({items}) => items),
          catchError((e) => {
            console.error(e);
            return [];
          })
        ))
    );
  }

  getPDFPassword(sn: IExtendedSickNote): string {
    return undefined;
  }

  clearSyncError(sick_note_id: string, vendor: string): Observable<any> {
    return this.api.post(API_LOG_SYNC(this.session.currentCompanyId),
      {
        items: [{
            sick_note_id,
            vendor,
            success: true,
            error: null
          }]
      });
  }

  overlapDialog(dialog: MatDialog) {
    return SimpleDialogComponent.open(dialog,
      {
        title: `Existe outro afastamento com data sobreposta a esse. Você pode cancelar e editar a data para remover a sobreposição ou forçar e salvar mesmo assim (não recomendado).`,
        noButton: 'CANCELAR',
        yesButton: 'FORÇAR',
        color: 'primary'
      },
      {width: '400px'}
    ).afterClosed();
  }

  verify(sickNoteId: string, verified = true): Observable<any> {
    return this.api.put(VERIFY_SN_API_URL(this.session.currentCompanyId, sickNoteId), {verified});
  }

  getVaccineTypes(): Observable<VaccineType[]> {
    return this.api.get(VACCINE_TYPES)
      .pipe(map(({items}: IResponseItems<VaccineType>) => items));
  }

  hasSyncError(syncs: SickNoteSync[]): boolean {
    return syncs?.some(({ success }) => !success) || false;
  }

  setFormTouched(formTouched: boolean): void {
    this.formTouched$.next(formTouched);
  }

  formIsTouched(): boolean {
    return this.formTouched$.getValue();
  }
}
