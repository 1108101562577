import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';

import {CanDeactivateGuard} from '../core/can-deactivate.guard';
import {LoadingDialogComponent} from '../loading-dialog/loading-dialog.component';
import {MaterialElevationDirective} from './utils/material-elevation-directive';
import {
  AgePipe,
  CpfFormatPipe,
  CnpjFormatPipe,
  IdentityFormatPipe,
  DateDiffPipe,
  FindPipe,
  FormatDate,
  FormatDocumentPipe,
  MapPipe,
  NameInitialsPipe,
  SomePipe,
  StringPipe,
  RouterBuilderPipe,
  SequencerPipe,
  ToHourPipe,
  HasTravelTimePipePipe,
  PersonActivePipe,
  CanEditPipe,
  AmountPipe,
  PendingStatus,
  RefusalReasonCategoriesPipe,
  CompleteDifferenceDate
} from './utils/pipes';
import {ReadableErrorPipe} from '../core/error-messages';
import {RecreateViewDirective} from './recreate-view.directive';
import {NoScrollDirective} from './directives/no-scroll.directive';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  providers: [
    AngularFireAuthGuard,
    CanDeactivateGuard,
    CompleteDifferenceDate
  ],
  declarations: [
    LoadingDialogComponent,
    MaterialElevationDirective,
    AgePipe,
    CpfFormatPipe,
    CnpjFormatPipe,
    IdentityFormatPipe,
    FormatDocumentPipe,
    NameInitialsPipe,
    SomePipe,
    FormatDate,
    MapPipe,
    DateDiffPipe,
    ReadableErrorPipe,
    FindPipe,
    StringPipe,
    RecreateViewDirective,
    NoScrollDirective,
    RouterBuilderPipe,
    SequencerPipe,
    ToHourPipe,
    HasTravelTimePipePipe,
    PersonActivePipe,
    CanEditPipe,
    AmountPipe,
    PendingStatus,
    RefusalReasonCategoriesPipe,
    CompleteDifferenceDate
  ],
  exports: [
    CommonModule,
    MaterialElevationDirective,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    AgePipe,
    CpfFormatPipe,
    CnpjFormatPipe,
    IdentityFormatPipe,
    NameInitialsPipe,
    SomePipe,
    FormatDate,
    MapPipe,
    DateDiffPipe,
    ReadableErrorPipe,
    FormatDocumentPipe,
    FindPipe,
    StringPipe,
    RecreateViewDirective,
    RouterBuilderPipe,
    SequencerPipe,
    ToHourPipe,
    HasTravelTimePipePipe,
    PersonActivePipe,
    CanEditPipe,
    AmountPipe,
    PendingStatus,
    RefusalReasonCategoriesPipe,
    CompleteDifferenceDate
  ]
})
export class SharedModule { }
