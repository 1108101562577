<app-container-header>

  <!-- Content Main -->
  <div contentMain class="d-flex flex-column box-sizing-border-box grid-container">
    <mat-progress-spinner mode="indeterminate" color="primary" [diameter]="40" *ngIf="loading; else showGrid"></mat-progress-spinner>
    <ng-template #showGrid>
      <mat-grid-list cols="2" rowHeight="fit" gutterSize="24px" class="flex-fill main-grid">
        <mat-grid-tile *ngFor="let card of cards | async" [colspan]="card.cols" [rowspan]="card.rows">
          <mat-card class="d-flex flex-column justify-content-between dashboard-card" [routerLink]="card.link">
            <img [src]="card.image" style="object-fit: contain; padding: 8px" class="image">
            <mat-card-footer class="dashboard-card-content">
              <div>{{card.title}}</div>
            </mat-card-footer>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-template>
  </div>
  <!-- Content Main -->

</app-container-header>
