<h2 mat-dialog-title>Aprovar documento</h2>
<form [formGroup]="form">
  <div *ngIf="!!situationsDataSource?.length">
    <h4>Selecione a situação</h4>
    <app-form-situations
      formControlName="situations"
      [interval]="data.interval"
      [dataSource]="situationsDataSource"
      [required]="true"
      [multiples]="multipleAllowed$ | async"
    ></app-form-situations>
  </div>
  <div>
    <h4>Escrever uma mensagem ao funcionário?</h4>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>(opcional) Mensagem ao funcionário</mat-label>
      <textarea matInput rows="3" formControlName="message"></textarea>
    </mat-form-field>
  </div>
</form>
<mat-dialog-actions align="end">
  <button
    mat-button
    type="button"
    color="primary"
    (click)="onNoClicked()"
  >CANCELAR</button>
  <button
    mat-button
    type="button"
    color="primary"
    [disabled]="!situationsDataSource || (
      !!situationsDataSource?.length && !situations?.value?.length
    )"
    (click)="onOkClick()"
  >APROVAR</button>
</mat-dialog-actions>
