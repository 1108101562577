import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../shared/shared.module';

import { TypesService } from 'src/app/services/types.service';
import { CompanyContentComponent } from './company-content/company-content.component';
import { ItemButtonComponent } from './item-button/item-button.component';
import { ItemContentComponent } from './item-content/item-content.component';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    MenuComponent,
    CompanyContentComponent,
    ItemButtonComponent,
    ItemContentComponent
  ],
  exports: [
    MenuComponent
  ],
  providers: [
    TypesService
  ]
})
export class MenuModule {}
