import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { environment } from '../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { TitleService } from './title.service';
import { CompanyService } from './company.service';
import * as Sentry from '@sentry/angular';
import { User } from '../shared/model/user';
import { UserService } from './user.service';
import { SessionService } from '../core/session.service';
import { differenceInSeconds } from 'date-fns';

export enum EVENT {
  APP_INIT = 'App Init',
  PAGE_VIEW = 'Pageview',
  SAVE_SICK_NOTE = 'Save Sick Note Click',
  NEW_PERSON = 'New Person',
  ERROR = 'Error',
  DASHBOARD_CHANGED = 'Dashboard Changed',
  LOCATION_CHANGED = 'Location Changed',
  IMPORT_CLICK = 'Import Click',
  EXPORT_CLICK = 'Export Click',
  FILTER_CLICK = 'Filter Click',
  LOGIN_CLICK = 'Login Click',
  EDIT_CLICK = 'Edit Click',
  PAGE_CLOSE = 'Page Close',
  DOCUMENT_APPROVE = 'Document Approve',
  DUCUMENT_REPROVE = 'Document Reprove'
}

export enum PROPERTY {
  PAGE_TITLE = 'page_title',
  PAGE_URL = 'page_path'
}

export enum WHERE {
  SICK_NOTES_LIST = 'SickNotesList',
  DASHBOARD = 'DashboardComponent',
  SICK_NOTES_DIALOG = 'SickNotesAnalyzeDialog',
  DOCUMENTS_DIALOG = 'DocumentsDetailsDialog',
  DOCUMENTS_EDIT_DIALOG = 'DocumentsEditDialog',
  DIGITALIZE_SICK_NOTES = 'DigitalizeSickNoteDialog',
  SICK_NOTES_EDIT = 'SickNoteEditDialog',
  OCCUPACIONAL_LIST = 'OccupacionalEditDialog',
  REFUND_LIST = 'RefundEditDialog'
}

export enum TYPECLOSURE {
  APPROVE = 'Approve',
  CLOSED_MANUALLY = 'Manual',
  BEFOREUNLOAD = 'Beforeunload',
  REFUSE = 'Refuse',
  CLOSE_WINDOW = 'Window Closed'
}

@Injectable({
  providedIn: 'root'
})
export class Analytics {
  idsRegExp = /^\d+$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  constructor(private ga: AngularFireAnalytics,
    private title: TitleService,
    private companyService: CompanyService,
    private session: SessionService,
    private userService: UserService) { }

  get amplitude() {
    return amplitude.getInstance();
  }

  startTracking() {
    const amplitudeConfig: amplitude.Config = {
      logLevel: environment.production ? 'ERROR' : 'WARN',
    };
    this.amplitude.init(environment.amplitudeApiKey, undefined, amplitudeConfig);
    this.amplitude.setVersionName(environment.version);

    this.session.loggedInUser$.subscribe(
      user => {
        this.updateUser(user);
      },
      error => {
        Sentry.captureMessage(error.message);
      }
    );
  }

  pageView(url: string) {
    const clearUrl = this.clearUrl(url),
      properties: any = {};
    properties[PROPERTY.PAGE_URL] = clearUrl;
    properties[PROPERTY.PAGE_TITLE] = this.title.getTitle();
    properties.company_id = this.session.currentCompanyId;
    this.amplitude.logEvent(EVENT.PAGE_VIEW, properties);
    this.ga.logEvent('screen_view', properties).then();
  }

  newPerson(search: string) {
    this.event(EVENT.NEW_PERSON, { search });
  }

  error(where: WHERE | string, what: string) {
    this.event(EVENT.ERROR, null, where, what);
  }

  updateUser(user: User | null) {
    if (user) {
      this.amplitude.setUserId(user.id);
      this.amplitude.setUserProperties({
        email: user.email,
        name: user.name,
      });
    }
  }

  event(action: EVENT, properties?: any, where?: WHERE | string, what?: string) {
    if (!properties) {
      properties = {};
    }
    properties.where = where;
    properties.what = what;
    properties.company_id = this.session.currentCompanyId;
    this.amplitude.logEvent(action, properties);
    this.ga.logEvent(action, properties).then();
  }

  private clearUrl(url: string): string {
    return url
      .replace(/c\/[a-zA-Z0-9]+(\/.*)?/g, 'c/companyId$1')
      .split('/')
      .map(part => part.split('?')[0])
      .map(part => part.split('#')[0])
      .filter(part => !part.match(this.idsRegExp))
      .join('/');
  }

  trackingPageViewTime(where?: WHERE, openTime?: Date, closeTime?: Date, event?: EVENT) {
    const timeOnScreen = differenceInSeconds(closeTime, openTime),
      properties: any = {},
      action = event;
    properties.company_id = this.session.currentCompanyId;
    properties.where = where;
    properties.timeOnScreen = timeOnScreen;
    properties.userEmail = this.session.companyUser.email;
    this.amplitude.logEvent(action, properties);
  }

  pageClosed(where?: WHERE, openTime?: Date, closeTime?: Date, type?: TYPECLOSURE) {
    const timeOnScreen = differenceInSeconds(closeTime, openTime),
      properties: any = {},
      action = EVENT.PAGE_CLOSE;
    properties.company_id = this.session.currentCompanyId;
    properties.where = where;
    properties.timeOnScreen = timeOnScreen;
    properties.type = type;
    properties.userEmail = this.session.companyUser.email;
    this.amplitude.logEvent(action, properties);
  }
}

