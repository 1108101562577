import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRouteSnapshot, CanActivateChild, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CanActivateChildGuard implements CanActivateChild {
  constructor(private dialog: MatDialog,
              private router: Router,
              private readonly location: Location) {}

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    if (this.dialog.openDialogs.length > 0) {
      const currentUrlTree = this.router.createUrlTree([], route);
      const currentUrl = currentUrlTree.toString();
      this.location.go(currentUrl);
      this.dialog.closeAll();
      return false;
    } else {
      return true;
    }
  }
}
