import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { UserService } from '../services/user.service';
import { User } from '../shared/model/user';
import { addHours, isBefore } from 'date-fns';
import { getIdTokenResult } from 'firebase/auth';

export const ERROR_CODES = {
  'auth/invalid-argument': 'Erro: Um argumento inválido foi fornecido.',
  'auth/invalid-disabled-field': 'Erro: O valor fornecido para a propriedade de usuário é inválido.',
  'auth/wrong-password': 'Erro: Usuário ou senha inválidos',
  'auth/admin-restricted-operation': 'Erro: Usuário ainda não cadastrado. Procure o RH da empresa para criar uma conta.',
  'auth/invalid-action-code': 'Esse link de login já expirou ou já foi utilizado. Continue para solicitar um novo link.',
  'auth/internal-error': 'Erro: Não foi possível acessar. Tente novamente.',
  'USER_NOT_FOUND': 'Erro: Email não cadastrado. Procure o RH da sua empresa para solicitar seu cadastro.',
  'RECAPTCHA_FAIL': 'Erro ao validar. Por favor, tente novamente ou fale com o suporte no botão "Ajuda"'
};

export const DEFAULT_EXPIRATION_INTERVAL_HOURS = 168;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token$: Observable<string | null>;
  public isLoggedIn$ = new Observable<boolean>();
  public authUser$: Observable<firebase.User>;

  constructor(
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private session: SessionService,
    private userService: UserService
  ) {
    this.token$ = this.firebaseAuth.idToken;
    this.authUser$ = this.firebaseAuth.user;
    this.firebaseAuth.user.subscribe((user) => {
      if (user) {
          getIdTokenResult(user).then((decodedToken) => {
            let expirationInterval = DEFAULT_EXPIRATION_INTERVAL_HOURS;
            if ('expiration_interval' in decodedToken.claims) {
              expirationInterval = decodedToken.claims.expiration_interval;
            }
            if (!this.isLoginRecent(user.metadata.lastSignInTime, expirationInterval)) {
              console.warn('Old credentials. Will logout');
              this.logout(true).then();
            }
          });
      }
    });

    this.isLoggedIn$ = this.authUser$.pipe(
      map((user) => !!user && !user.isAnonymous)
    );
    this.session.loggedInUser$ = this.authUser$.pipe(
      mergeMap((firebaseUser) => of(firebaseUser)),
      mergeMap((firebaseUser) => {
        const getUser = firebaseUser ? this.userService.getProfile() : of(null);
        return combineLatest([of(firebaseUser), getUser]);
      }),
      map(([firebaseUser, user]: [firebase.User, User]) => {
        if (user) {
          user.last_sign_in = firebaseUser.metadata.lastSignInTime;
        }
        return user;
      }),
      shareReplay(1)
    );
  }

  logout(redirect = true) {
    return this.firebaseAuth.signOut().then(() => {
      localStorage.clear();
      if (redirect) {
        this.router.navigate(['/login']);
      }
    });
  }

  public errorByCode(code: string): string {
    if (ERROR_CODES[code]) {
      return (ERROR_CODES[code]);
    } else {
      return ('Ocorreu um erro! Tente novamente ou fale com o suporte pelo botão de ajuda.' + (code ? ' Codigo: ' + code : ''));
    }
  }

  isLoginRecent(lastSignIn: string, expirationInterval: number): boolean {
    const signInDate = new Date(lastSignIn),
     expirationDate = addHours(signInDate, expirationInterval);
     return isBefore(new Date(), expirationDate);
  }
}
