import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive()
export class BaseClass implements OnDestroy {
  unsubscribe: Subject<void> = new Subject();

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
