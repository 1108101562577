<ng-content select="[content]"></ng-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    type="button"
    color="primary"
    (click)="onCancel.emit(false)"
  >{{ cancelText }}</button>
  <button
    mat-button
    type="button"
    color="primary"
    [disabled]="confirmDisabled"
    (click)="onConfirm.emit()"
  >{{ confirmText }}</button>
</mat-dialog-actions>

