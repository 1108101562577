import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {SharedModule} from 'src/app/shared/shared.module';

import {SubmenuComponent} from './submenu.component';
import {SubItemButtonComponent} from './subitem-button/subitem-button.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    SubmenuComponent,
    SubItemButtonComponent
  ],
  exports: [
    SubmenuComponent
  ]
})
export class SubmenuModule {}
