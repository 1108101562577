<header>
  <h3>Notificações</h3>
</header>
<mat-action-list dense class="list">
  <div *ngFor="let notification of notificationService.notifications$ | async; let i = index">
    <mat-divider class="list__divider" *ngIf="i > 0"></mat-divider>
    <mat-list-item class="notification__item" (click)="openNotificationDetails(notification)">
      <div class="item__content">
        <app-notification-item [notification]="notification"></app-notification-item>
      </div>
    </mat-list-item>
  </div>
</mat-action-list>
