import {
  Pipe,
  PipeTransform
} from '@angular/core';

import {
  differenceInHours,
  differenceInMinutes
} from 'date-fns';

@Pipe({ name: 'differenceInTime', pure: true })
export class DifferenceInTimePipe implements PipeTransform {
  transform(from: string, to: string): any {
    const dateFrom = new Date(from);
    const dateTo = new Date(to);

    const diffHours = differenceInHours(dateTo, dateFrom);
    const diffMinutes = differenceInMinutes(dateTo, dateFrom);

    const treatedHours = ('0' + diffHours).slice(-2);
    const treatedMinutes = ('0' + (diffMinutes % 60)).slice(-2);

    return `${treatedHours}:${treatedMinutes}`;
  }
}

