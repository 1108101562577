import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {NotificationService} from './notification.service';
import {Observable} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {Dashboard} from '../shared/model/company';
import {Injectable} from '@angular/core';
import {DashboardService} from './dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardResolverService {
  constructor(public router: Router,
              private dashboardService: DashboardService,
              private notificationService: NotificationService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ar: ActivatedRoute):
    Observable<Dashboard | undefined> {

    const id = route.paramMap.get('dashboard_id');
    if (!!id) {
      return this.dashboardService.dashboards$.pipe(
        take(1),
        map((dashboards) => {
          if (!!dashboards) {
            return dashboards.find(d => d.id === id);
          } else {
            this.notificationService.messagesSubj.next('Nenhum dashboard encontrado com esse código: ' + id);
            this.router.navigate(['dashboard'], {relativeTo: ar});
            return undefined;
          }
        })
      );
    } else {
      // Take first dashboard available as default
      return this.dashboardService.dashboards$.pipe(take(1), map(dashboards => dashboards[0]));
    }
  }
}
