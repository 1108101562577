import {Component, OnInit} from '@angular/core';
import {interval} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';

@Component({
  selector: 'app-restricted',
  templateUrl: './restricted.component.html',
  styleUrls: ['./restricted.component.scss']
})
export class RestrictedComponent implements OnInit {
  seconds$;
  constructor() { }

  ngOnInit() {
    this.seconds$ = interval(1000).pipe(
      startWith(0),
      map((x) => 5 - x),
      map(timeout => {
        if (timeout === 0) {
          window.location.href = 'https://app.closecare.com.br';
        }
        return timeout;
      }),
      take(7)
    );
  }

}
