export class LRU<T> {
  private max: number;
  private cache: Map<string, T>;

  constructor(max= 10) {
    this.max = max;
    this.cache = new Map();
  }

  get(key): T {
    const item = this.cache.get(key);
    // refresh key
    if (item) {
      this.cache.delete(key);
      this.cache.set(key, item);
    }
    return item;
  }

  set(key: string, val: T): void {
    if (this.cache.has(key)) {  // refresh key
      this.cache.delete(key);
    } else if (this.cache.size === this.max) { // evict oldest
      this.cache.delete(this._first());
    }
    this.cache.set(key, val);
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  _first(): string {
    return this.cache.keys().next().value;
  }
}
