import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Dashboard} from '../shared/model/company';
import {catchError, filter, map, mergeMap, shareReplay} from 'rxjs/operators';
import {ApiService, DASHBOARDS, METABASE_CF_API} from '../core/api.service';
import {SessionService} from '../core/session.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboards$: Observable<Dashboard[]>;

  constructor(private api: ApiService,
              private session: SessionService) {

    this.dashboards$ = this.getUserDashboards().pipe(shareReplay(1));
  }

  private getUserDashboards(): Observable<Dashboard[]> {
    return this.session.currentCompanyId$
      .pipe(
        filter((companyId) => !!companyId),
        mergeMap((companyId) => {
          const permissions = this.session.permissions;
          if (!companyId || !permissions?.roles?.company) {
            return of([]);
          }
          return this.api.get<{items: Dashboard[]}>(DASHBOARDS(companyId))
            .pipe(map((result) => result?.items));
        }),
        catchError(e => {
          return of([]);
        })
      );
  }

  getDashboardUrl(id: string, body: any): Observable<string> {
    return this.api.post<{ url: string }>(METABASE_CF_API(this.session.currentCompanyId, id), body)
      .pipe(map(res => res.url));
  }
}
