import {NgModule} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';

import {SharedModule} from '../../shared/shared.module';

import {NotificationItemModule} from '../notification-item/notification-item.module';
import {NotificationsListComponent} from './notifications-list.component';

@NgModule({
  imports: [
    MatDividerModule,
    MatIconModule,
    MatListModule,
    SharedModule,
    NotificationItemModule
  ],
  declarations: [
    NotificationsListComponent
  ],
  exports: [
    NotificationsListComponent
  ]
})

export class NotificationsListModule { }
