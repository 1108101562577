<ng-container *ngIf="!item.hover; else hover">
  <button
    class="menu__button"
    routerLinkActive="menu__button--selected"
    [routerLink]="item.routes | routerBuilder: companyId"
    [routerLinkActiveOptions]="{ exact: item.exact }"
    [attr.aria-label]="item.title"
    [tabindex]="tabIndex || -1"
  >
    <app-item-content
      class="menu__item-content"
      [icon]="item.icon"
      [title]="item.title"
    ></app-item-content>
  </button>
</ng-container>
<ng-template #hover>
  <button
    class="menu__button menu__button--hover"
    [routerLink]="routerLink | routerBuilder: companyId"
    [attr.aria-label]="item.title"
    [tabindex]="tabIndex || -1"
    [ngClass]="{
      'menu__button--selected': matchPath(item.routes[item.routes.length - 1])
    }"
    (mouseenter)="sidebarService.hoverItem(item)"
    (mouseleave)="sidebarService.blurItem()"
    (click)="sidebarService.hoverItem(item)"
  >
    <app-item-content
      class="menu__item-content"
      [icon]="item.icon"
      [title]="item.title"
    ></app-item-content>
  </button>
</ng-template>
