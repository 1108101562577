import {Component, HostListener, OnInit} from '@angular/core';
import {PeopleService} from '../services/people.service';

import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {take, tap} from 'rxjs/operators';
import {AuthService} from '../core/auth.service';
import {ApiService} from '../core/api.service';
import {Dashboard} from '../shared/model/company';
import {Observable} from 'rxjs';
import {METABASE_URL} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {Analytics, EVENT, WHERE} from '../services/analytics';
import {CompanyService} from '../services/company.service';
import {
  IV3Location,
  IV3LocationResponse
} from 'src/app/shared/interfaces/location.interface';
import {DashboardService} from '../services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  metabaseUrl: string;
  safeMetabaseUrl: SafeResourceUrl;
  locations: Observable<IV3LocationResponse>;
  previousLocation: IV3Location;
  selectedLocation: IV3Location;
  hashLocationIds: number;
  loading = true;
  reloading = false;
  urlHash: string;
  dashboard: Dashboard;

  constructor(private peopleService: PeopleService,
              private sanitizer: DomSanitizer,
              private authService: AuthService,
              private companyService: CompanyService,
              private analytics: Analytics,
              private route: ActivatedRoute,
              private api: ApiService,
              private dashboardService: DashboardService) {}

  @HostListener('window:message', ['$event'])
  onMetabaseRemoteMessage(event: any) {
    if (event.origin === METABASE_URL &&
      event.data.source === 'Metabase' && event.data.name === 'dashboard-parameters') {

      this.urlHash = event.data.value.slice(1);
      window.location.hash = this.urlHash;
      if (!!this.selectedLocation) {
        window.location.hash += '&cl=' + this.selectedLocation.id;
      }

      this.analytics.event(EVENT.DASHBOARD_CHANGED, {hash: !!this.urlHash ? this.urlHash.split('&') : []}, WHERE.DASHBOARD);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);

    this.urlHash = window.location.hash.slice(1);  // strip leading # char
    this.hashLocationIds = parseInt(new URLSearchParams(this.urlHash).get('cl'), 10);  // location_code found in URL hash
    // location_code should not go to metabase URL
    this.urlHash = ('' + this.urlHash).replace('&cl=' + this.hashLocationIds, '');
    this.locations = this.getLocations();

    this.route.data.subscribe((data: {dashboard: Dashboard}) => {
      this.dashboard = data.dashboard;
      this.loadDashboardUrl([this.hashLocationIds]);
    });
  }

  buildMetabaseUrl(url: string) {
    this.metabaseUrl = url;
    if (!!this.urlHash) {
      this.metabaseUrl += '?' + this.urlHash;
    }
    this.safeMetabaseUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.metabaseUrl + '#bordered=false&titled=false');

    setTimeout(() => {
      this.reloading = false;
    }, 1000);
  }

  // https://github.com/metabase/metabase/pull/7817

  loadDashboardUrl(location_ids?: number[]) {
    const dashboardId = this.dashboard?.id;
    const params = {
      location_ids: !!location_ids ? location_ids.filter(item => !!item) : []
    };
    this.dashboardService.getDashboardUrl(dashboardId, params)
      .subscribe(url => {
        this.buildMetabaseUrl(url.split('#')[0]);
      });
  }

  getLocations(): Observable<IV3LocationResponse> {
    return this.companyService.getLocations().pipe(
      take(1),
      tap(({ locations }: IV3LocationResponse) => {
        // Set location that came in the URL
        this.previousLocation = this.selectedLocation = locations.find(location => location.id === this.hashLocationIds);
      })
    );
  }

  locationChanged(location: IV3Location) {
    this.updateHash();
    if (location !== this.previousLocation) { // Prevent reload if value not changed
      this.previousLocation = location;
      this.reloading = true;
      this.loadDashboardUrl(!!location ? [location.id] : []);
    }

    this.analytics.event(EVENT.LOCATION_CHANGED, {location: !!location ? location.id : ''}, WHERE.DASHBOARD);
  }

  // Update location hash in the URL
  updateHash() {
    if (!!this.hashLocationIds) {
      window.location.hash = window.location.hash.replace('&cl=' + this.hashLocationIds, '');
      window.location.hash = window.location.hash.replace('cl=' + this.hashLocationIds, '');
    }
    if (!!this.selectedLocation) {
      if (!!window.location.hash) {
        window.location.hash += '&';
      }
      window.location.hash += 'cl=' + this.selectedLocation.id;
      this.hashLocationIds = this.selectedLocation.id;
    } else {
      this.hashLocationIds = undefined;
    }
  }
}
