export const PERMISSIONS = {
  aso_r: 'aso_r',
  aso_w: 'aso_w',
  aso_v: 'aso_v',
  dashboard_r: 'dashboard_r',
  sick_note_r: 'sick_note_r',
  sick_note_w: 'sick_note_w',
  sick_note_v: 'sick_note_v',
  sick_note_c: 'sick_note_c',
  ticket_r: 'ticket_r',
  person_r: 'person_r',
  person_w: 'person_w',
  import_w: 'import_w',
  import_sn: 'import_sn',
  notes_r: 'notes_r',
  user_r: 'user_r',
  user_w: 'user_w',
  config_w: 'config_w',
};

export interface PermissionDetails {
  id: string;
  label: string;
  description: string;
}

export class PermissionList {
  aso_r: string;
  aso_w: string;
  aso_v: string;
  dashboard_r: string;
  sick_note_r: string;
  sick_note_w: string;
  sick_note_v: string;
  sick_note_c: string;
  ticket_r: string;
  person_r: string;
  person_w: string;
  import_w: string;
  import_sn: string;
  notes_r: string;
  user_r: string;
  user_w: string;
  config_w: string;
}
