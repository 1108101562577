import {
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

export class SituationsValidator {
  static required: ValidatorFn = (
    { controls }: UntypedFormArray
  ): ValidationErrors => (
    validation(controls['situations'].controls) ? null : { situationCondition: true }
  );
}

export const validation = (
  controls: any[]
): boolean => (
  !controls?.some((
    {
      value: {
        situation, duration, granted_days
      }
    }: UntypedFormControl
  ) => (
    !situation || (
      situation?.granted_days_required &&
      !(typeof granted_days === 'number')
    ) || (
      !situation?.granted_days_required &&
      (!duration || duration === '00:00')
    )
  ))
);
