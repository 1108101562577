<form class="small-form-field" [formGroup]="form">
  <app-category-select
    *ngIf="!disabled"
    [labelSelect]="'Selecione os motivos de recusa'"
    [labelSelected]="'Motivos da recusa'"
    [categories]="categories"
    [subCategoriesSelected]="reasons.value"
    (onChange)="reasonsChanged($event)"
  ></app-category-select>
  <app-form-single-select
    class="d-block pt-4px mt-16"
    formControlName="reason"
    [dataSource]="filteredReasons$ | async"
    [loaderSearch]="loaderSearch"
    [forceTouch]="forceTouch"
    [disabled]="disabled || !reasons.value?.length"
    [label]="'Selecione um modelo de mensagem'"
    [placeholder]="'Buscar mensagem'"
    [withDescriptions]="true"
    [errorText]="'Preencha o campo'"
  ></app-form-single-select>
  <mat-form-field class="mt-3 w-100" appearance="outline">
    <mat-label>Mensagem da recusa (o funcionário pode ler isso)</mat-label>
    <textarea
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="5"
      formControlName="message"
    ></textarea>
  </mat-form-field>
  <mat-error *ngIf="forceTouch">
    <span *ngIf="!reasons?.value?.length">
      Preencha o motivo e a mensagem para recusar o documento
    </span>
    <span *ngIf="!message?.value?.length">
      Preencha o campo acima
    </span>
  </mat-error>
</form>
