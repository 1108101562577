<mat-icon
  matBadgeColor="accent"
  matBadgeSize="small"
  *ngIf="notifications; else empty"
  [matBadge]="notifications"
>
  {{icon}}
</mat-icon>
<ng-template #empty>
  <mat-icon>{{icon}}</mat-icon>
</ng-template>
{{title}}
