<app-container-header>

  <!-- Content Main -->
  <div contentMain class="companies-list">
    <div
      class="picker-width d-flex flex-column justify-content-around align-items-stretch companies-list__wrapper mat-elevation-z2"
    >
      <mat-progress-spinner
        *ngIf="loading; else showPicker"
        diameter="40"
        mode="indeterminate"
        style="align-self: center"
      ></mat-progress-spinner>
      <ng-template #showPicker>
        <h3>Qual empresa você quer visualizar?</h3>
        <mat-action-list>
          <mat-divider></mat-divider>
          <button
            mat-list-item
            class="company-item"
            *ngFor="let company of companyService.companies$ | async"
            [class.selected]="company?.id === session.currentCompanyId"
            (click)="chooseCompany(company)"
          >
            <div class="w-100 d-flex justify-content-between align-items-center">
              <div>{{company.name}}</div>
              <button mat-flat-button color="accent" class="select-label">
                Selecionar
              </button>
            </div>
            <mat-divider></mat-divider>
          </button>
        </mat-action-list>
        <button mat-button color="primary" (click)="goBack()">CANCELAR</button>
      </ng-template>
    </div>
  </div>
  <!-- Content Main -->

</app-container-header>
