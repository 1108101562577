import { AbstractControl } from '@angular/forms';

import { format } from 'date-fns';

export const totalMinutes = (time: string): number => {
  const timeParts: string[] = time.split(':');

  return (Number(timeParts[0]) * 60) + Number(timeParts[1]);
};

export const totalHours = (time: number): string => {
  const hoursNumber: number = Math.floor(time / 60),
   minutesNumber: number = time - (hoursNumber * 60),

   hoursString: string = hoursNumber.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  }),

   minutesString: string = minutesNumber.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  return `${hoursString}:${minutesString}`;
};

export const setDateWithoutTimezone = (isoDate: string): Date => {
  const date = new Date(isoDate);

  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};

export const setISOStringWithoutTimezone = (completeDate: Date): string => {
  const date = format(completeDate, 'yyyy-MM-dd'),
   time = format(completeDate, 'HH:mm:ss');

  return `${date}T${time}.000Z`;
};

export class TimeValidator {
  static travelMinutes() {
    return (control: AbstractControl): {limitHours: true} | null => {
      const hour: number = totalMinutes(control.value);

      return hour > ((9 * 60) - 1) ? {limitHours: true} : null;
    };
  }
}
