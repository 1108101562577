import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { Category } from 'src/app/shared/model/reprovation';
import {
  IPill,
  IPillOutput
} from 'src/app/components/pills/entities/pills.interface';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss']
})
export class CategorySelectComponent {
  @Input() labelSelect: string;
  @Input() labelSelected: string;
  @Input() noMarginTop: boolean;

  @Output() onChange = new EventEmitter<Category[]>();

  pills: IPill[];
  selectedCategory: Category;
  selectedSubCategories: Category[] = [];

  private _categories: Category[];

  @Input()
  get categories(): Category[] {
    return this._categories;
  }
  set categories(categories: Category[]) {
    this._categories = categories;
    this.pills = categories?.map(
      ({ id, name }: Category) => ({ id, name })
    ) || [];

    this.setSelectedCategory();
  }

  @Input() set subCategoriesSelected(subCategories: Category[]) {
    if (
      !!subCategories?.length &&
      !this.selectedCategory &&
      !this.selectedSubCategories.length
    ) {
      this.selectedSubCategories = subCategories;
      this.setSelectedCategory();
    }
  };

  pillsChanged(pill: IPillOutput): void {
    this.selectedCategory = this.categories
      .find(({ id }: Category) => id === pill.id);
  }

  selectSubCategory(subCategory: Category): void {
    document.body.click();

    const index = this.selectedSubCategoriesIds
      .indexOf(subCategory.id);

    if (index > -1) {
      this.selectedSubCategories.splice(index, 1);
    } else {
      this.selectedSubCategories.push(subCategory);
    }

    this.onChange.emit(this.selectedSubCategories);
  }

  subCategorySelected(subCategoryId: number): boolean {
    return this.selectedSubCategoriesIds
      .includes(subCategoryId);
  }

  private get selectedSubCategoriesIds(): number[] {
    return this.selectedSubCategories
      .map(({id}) => id);
  }

  private setSelectedCategory(): void {
    if (this.categories?.length && this.selectedSubCategories?.length) {
      this.categories.some((category: Category, i: number) => {
        const match = category.children.find(({ id }: Category) => (
          this.selectedSubCategoriesIds.includes(id)
        ));

        if (!!match) {
          this.pills[i].selected = true;
          this.selectedCategory = category;
        }

        return match;
      });
    }
  }
}
