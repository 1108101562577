import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApiService, ListAPIResponse, PEOPLE_URL, SEARCH_PEOPLE_URL} from '../core/api.service';
import {HttpParams} from '@angular/common/http';
import {PERMISSIONS} from '../core/roles';
import {SessionService} from '../core/session.service';
import {UserService} from './user.service';
import {Person, PersonPayload} from '../shared/model/person';
import {CpfFormatPipe} from '../shared/utils/pipes';

export interface PeopleSearchResponse {
  items: Person[];
  /**
   * @deprecated
   */
  people: Person[];
}

export interface ListPeopleConfig {
  limit?: number;
  page?: number;
  query?: string;
  managers?: boolean;
  person_id?: string;
  location_ids?: number[];
  email?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  static getNameInitials(name: string) {
    const names = ('' + name).split(' ');
    let initials = names[0].substr(0, 1);

    if (names.length > 1) {
      initials += names[names.length - 1].substr(0, 1);
    }
    return initials;
  }

  static personIDString(document_id: string, document_type: string): string {
    if (!document_id || !document_type) {
      return '';
    }
    if (document_type.toUpperCase() === 'CPF') {
      return new CpfFormatPipe().transform(document_id);
    } else {
      return 'ID: ' + document_id;
    }
  }

  static cleanCPF(value: string) {
    return value.replace(/[^A-Za-z0-9]/g, '').substring(0, 11);
  }

  static cleanDocumentId(document_id: string, document_type: string) {
    if (document_type === 'cpf') {
      return this.cleanCPF(document_id);
    } else {
      return document_id;
    }
  }

  static isCpfValid(strCPF: string): boolean {
    let sum = 0,
     rest;

    strCPF = PeopleService.cleanCPF(strCPF);
    if (strCPF === '00000000000' || ('' + strCPF).length > 11) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      sum = sum + Number(strCPF.substring(i - 1, i)) * (11 - i);
    }
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) {
      rest = 0;
    }
    if (rest !== Number(strCPF.substring(9, 10))) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + Number(strCPF.substring(i - 1, i)) * (12 - i);
    }
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) {
      rest = 0;
    }
    return rest === Number(strCPF.substring(10, 11));
  }

  constructor (
    private session: SessionService,
    private userService: UserService,
    private api: ApiService
  ) {}

  listPeople(config: ListPeopleConfig = {}): Observable<ListAPIResponse<Person>> {
    const params = {
      obj_per_page: config.limit?.toString() || '100',
      page: config.page?.toString() || '1',
      email: config.email,
      query: config.query,
      managers: config.managers?.toString(),
      person_id: config.person_id,
      location_ids: config.location_ids?.map((v) => v?.toString())
    };

    Object.keys(params).forEach((k) => (
      params[k] === undefined && delete params[k]
    ));

    return this.api.get<ListAPIResponse<Person>>(
      PEOPLE_URL(this.session.currentCompanyId),
      { params }
    ).pipe(map((res) => {
      res.items = res.items.map((p) => new Person(p));
      return res;
    }));
  }

  addPerson(person: PersonPayload): Observable<string> {
    return this.api.post<string>(PEOPLE_URL(this.session.currentCompanyId), person)
      .pipe(map(id => id));
  }

  editPerson(personId: string, person: PersonPayload): Observable<string> {
    return this.api.put<string>(`${PEOPLE_URL(this.session.currentCompanyId)}/${personId}`, person)
      .pipe(map(id => id));
  }

  getPerson(personId): Observable<Person> {
    return this.api.get<Person>(`${PEOPLE_URL(this.session.currentCompanyId)}/${personId}`);
  }

  searchPeopleByField(query: string): Observable<Person[]> {
    if (!query && query !== '') {
      return of([]);
    }

    const params = new HttpParams()
      .set('query', query)
      .set('obj_per_page', '100');
    return this.api.get<PeopleSearchResponse>(
      SEARCH_PEOPLE_URL(this.session.currentCompanyId),
      {params}
    ).pipe(
      map(value => value ? value.items || value.people : []),
      catchError(() => of([]))
    );
  }

  canEdit(): Observable<boolean> {
    return this.session.permissions$.pipe(
      map((permissions) => this.userService.hasPermissions([PERMISSIONS.person_w], permissions))
    );
  }
}
