import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig} from '@angular/material/legacy-snack-bar';
import {SwUpdate, UpdateAvailableEvent} from '@angular/service-worker';
import {filter, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor (
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate
  ) {}

  detection(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available
        .pipe(
          filter(({type}: UpdateAvailableEvent) => {
            return type === 'UPDATE_AVAILABLE';
          }),
          mergeMap(() => {
            const config: MatSnackBarConfig = new MatSnackBarConfig();
            config.panelClass = 'update-snackbar';

            return this.snackBar.open(
              'Existe uma nova versão do site disponível',
              'Atualizar',
              config
            ).afterDismissed();
          })
        )
        .subscribe(() => this.forceUpdate());

      this.swUpdate.unrecoverable
        .subscribe(() => this.forceUpdate());
    }
  }

  forceUpdate() {
    window.location.reload();
  }
}
