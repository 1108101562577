import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {SharedModule} from '../../shared/shared.module';

import {NotificationItemComponent} from './notification-item.component';

@NgModule({
  imports: [
    MatIconModule,
    SharedModule
  ],
  declarations: [
    NotificationItemComponent
  ],
  exports: [
    NotificationItemComponent
  ]
})

export class NotificationItemModule { }
