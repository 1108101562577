import {Injectable} from '@angular/core';

import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import moment from 'moment';

import {ApiService, NOTIFICATIONS_USERS_LIST_API_URL, SICK_NOTE_API_URL} from '../core/api.service';
import * as Notification from '../shared/model/notification';
import {SickNoteService} from './sick-note.service';
import {UserService} from './user.service';
import {SessionService} from '../core/session.service';
import {PERMISSIONS} from '../core/roles';
import { HttpParams } from '@angular/common/http';

import { IResponseList } from 'src/app/shared/interfaces/response.interfaces';
import { IUserIDs } from 'src/app/shared/model/user';
import { StatusSyncResponse } from '../shared/model/notification';


const pullingTime = 1000 * 60 * 30;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public messagesSubj: Subject<string> = new Subject();

  private countdown: any;

  private notificationsSubj = new BehaviorSubject<Notification.Interface[]>([]);
  public notifications$ = this.notificationsSubj.asObservable();

  constructor(
    private api: ApiService,
    private session: SessionService,
    private userService: UserService,
    private sickNoteService: SickNoteService
  ) {
    moment.locale('pt-br');

    this.session.permissions$
      .pipe(
        tap(() => this.setNotifications([])),
        map((permissions) => this.userService.hasPermissions([PERMISSIONS.sick_note_w], permissions))
      )
      .subscribe(hasPermissions => {
        if (hasPermissions) {
          this.startSyncError();
        }
      });
  }

  public startSyncError(): void {
    setTimeout(() => this.setSyncError(), 5000);

    clearInterval(this.countdown);
    this.countdown = setInterval(() => this.setSyncError(), pullingTime);
  }

  public setNotifications(notifications: Notification.Interface[]) {
    this.notificationsSubj.next(notifications);
  }

  public getNotification(notificationId: string, userId: string): Observable<boolean> {
    const params = new HttpParams();
      params.set('user_id', userId);

    return this.api.get(
      NOTIFICATIONS_USERS_LIST_API_URL(
        this.session.currentCompanyId,
        notificationId
      ), { params }
    ).pipe(map(({ items }: IResponseList<IUserIDs>) => (
      items.some(({ user_id }: IUserIDs) => user_id === userId)
    )));
  }

  public getAwaitingSickNotesNotification(userId: string): Observable<boolean> {
    return this.getNotification('awaiting_sick_notes', userId);
  }

  public getValidatedSickNotesNotification(userId: string): Observable<boolean> {
    return this.getNotification('validated_sick_notes', userId);
  }

  public createNotification(notificationId: string, userId: string) {
    return this.api.post(NOTIFICATIONS_USERS_LIST_API_URL(this.session.currentCompanyId, notificationId), { user_ids: [userId] });
  }

  public removeNotification(notificationId: string, userId: string) {
    return this.api.delete(NOTIFICATIONS_USERS_LIST_API_URL(this.session.currentCompanyId, notificationId, userId));
  }

  public updateAwaitingSickNotesNotification(userId: string, awaitingSickNotes: boolean) {
    if (awaitingSickNotes) {
      return this.createNotification('awaiting_sick_notes', userId);
    } else {
      return this.removeNotification('awaiting_sick_notes', userId);
    }
  }

  public updateValidatedSickNotesNotification(userId: string, validatedSickNotes: boolean) {
    if (validatedSickNotes) {
      return this.createNotification('validated_sick_notes', userId);
    } else {
      return this.removeNotification('validated_sick_notes', userId);
    }
  }

  private setSyncError() {
    forkJoin({
      allErrors: this.api.get<StatusSyncResponse>(
        `${SICK_NOTE_API_URL(this.session.currentCompanyId)}/sync/error`, {}
      ),
      invalidErrors: this.sickNoteService.listSickNotes(
        1, ['invalid'], undefined, [{ field: 'sync_status', value: ['error'] }]
      )
    }).subscribe((response) => {
      const invalid = response?.invalidErrors?.items?.length || 0;
      let errorsSum = 0;
      Object.keys(response.allErrors).forEach(key => {
        errorsSum += response.allErrors[key];
      })
      const validQuantity = errorsSum - invalid;            
      const notifications: Notification.Interface[] = [];

      if (invalid) {
        notifications.push({
          id: '',
          errors: invalid,
          type: Notification.TypeEnum.critical,
          status: Notification.StatusEnum.invalid,
          created_at: moment(new Date()).fromNow(),
          route: 'sick_notes?t=invalid&f.sync_status=error',
        });
      }

      if (validQuantity) {
        notifications.push({
          id: '',
          errors: validQuantity,
          type: Notification.TypeEnum.critical,
          status: Notification.StatusEnum.valid,
          created_at: moment(new Date()).fromNow(),
          route: 'sick_notes?t=valid&f.sync_status=error',
        });
      }

      this.setNotifications(notifications);
    });
  }
}
