import {Component} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

import {AuthService} from '../core/auth.service';
import {ProfileSettingsComponent} from './settings/settings.component';
import {environment} from '../../environments/environment';
import {SessionService} from '../core/session.service';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})

export class ProfilePopupComponent {
  public isDev = !environment.production;

  constructor(
    private authService: AuthService,
    public session: SessionService,
    private dialog: MatDialog
  ) {}

  public settings(): void {
    this.dialog.open(ProfileSettingsComponent, {
      // maxWidth: '450px'
    })
    .afterClosed()
    .subscribe();
  }

  public logout(): void {
    this.authService.logout();
  }
}
