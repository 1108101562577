import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';

import {
  BehaviorSubject,
  Subject
} from 'rxjs';
import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes
} from 'date-fns';

import { ITime } from 'src/app/shared/interfaces/date.interfaces';
import { Utils } from 'src/app/shared/utils/utils';

import {
  setDateWithoutTimezone,
  setISOStringWithoutTimezone
} from 'src/app/shared/utils/time';

import { ETimeType } from '../shared/time.enums';
import { IInterval } from '../interval/entities/interval.interfaces';
import {
  ISituationAPIResponse,
  ISituationForm,
  ISituation
} from './entities/situations.interfaces';
import { FormSituationsService } from './situations.service';
import {
  SituationsValidator,
  validation
} from './situations.validator';

@Component({
  selector: 'app-form-situations',
  templateUrl: './situations.component.html',
  styleUrls: [
    './situations.component.scss',
    '../shared/time.style.scss'
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FormSituationsComponent
    }
  ]
})
export class FormSituationsComponent implements
  OnInit,
  OnDestroy,
  MatFormFieldControl<ISituation[]>,
  ControlValueAccessor {
  @Input() hideDates = false;
  @Input() multiples = true;

  id: string;
  form: UntypedFormGroup;
  placeholder: string;
  focused: boolean;
  shouldLabelFloat: boolean;
  errorState: boolean;
  controlType?: string | undefined;
  autofilled?: boolean | undefined;
  userAriaDescribedBy?: string | undefined;

  dateFormat = 'dd/MM/YYYY';
  maxTime = '12:00' as ITime;
  maxDays = 0;
  type = ETimeType.day;
  initialDate: string;
  endDate: string;
  filteredSituations: ISituationAPIResponse[][];

  addReleased = false;
  stateChanges = new Subject<void>();
  loader$ = new BehaviorSubject<boolean>(true);

  private _dataSource: ISituationAPIResponse[];
  private _disabled: boolean;
  private _required: boolean;
  private _forceTouch: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private formSituationsService: FormSituationsService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.form = this.formBuilder.group({
      situations: this.formBuilder.array([])
    }, { validation: SituationsValidator.required });
  }

  @Input()
  set interval(value: IInterval) {
    if (value !== null) {
      const initialDateValidation = value?.initialDate instanceof Date,
       hasEndDate = !!value?.endDate,
       endDateValidation = value?.endDate instanceof Date,
       errorPrefix = 'Interval -> ';

      // Checking if "value" doesn't match type IInterval
      if (!initialDateValidation || (hasEndDate && !endDateValidation)) {
        throw new Error(`${errorPrefix}value must be "IInterval" type`);
      }

      if (hasEndDate) {
        const minutesDifference = differenceInMinutes(
          value.endDate,
          value.initialDate
        );

        if (minutesDifference < 0) {
          throw new Error(
            `${errorPrefix}"endDate" must have date after "initialDate"`
          );
        }

        this.setRules(value, false);
      }
    }

    this.stateChanges.next();
  }

  @Input()
  get dataSource(): ISituationAPIResponse[] {
    return this._dataSource;
  }
  set dataSource(value: ISituationAPIResponse[]) {
    this._dataSource = value;
    this.setAddRelease();
    this.loader$.next(false);
  }

  @Input()
  set value(value: ISituation[]) {
    if (value !== null) {
      if (!Array.isArray(value)) {
        throw new Error(
          'Situations -> value must be "ISituation[]" type'
        );
      }

      this.buildSituations(value, false);
    }

    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: undefined | boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.setDisabled(true);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: undefined | boolean) {
    this._required = coerceBooleanProperty(value);
    this.setValidator(true);
    this.stateChanges.next();
  }

  @Input()
  get forceTouch(): boolean {
    return this._forceTouch;
  }
  set forceTouch(value: undefined | boolean) {
    this._forceTouch = coerceBooleanProperty(value);
    this.setForceTouch(true);
    this.stateChanges.next();
  }

  @Input() set typeId(typeId: number) {
    this.loader$.next(true);
    this.formSituationsService.get(typeId)
      .subscribe({
        next: (situations: ISituationAPIResponse[]) => {
          this.dataSource = situations;
          this.setAddRelease();
          this.stateChanges.next();
          this.loader$.next(false);
        },
        error: () => this.loader$.next(false)
      });
  };

  ngOnInit(): void {
    this.form.valueChanges
      .subscribe(() => {
        this.updateValues();
        this.setAddRelease();
      });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  get situationsArray(): UntypedFormArray {
    return this.form.get('situations') as UntypedFormArray;
  }

  get empty(): boolean {
    return !validation(this.situationsArray.controls);
  }

  // Storybook: Workaround for not accept direct use of enum in template
  // https://github.com/storybookjs/storybook/issues/16386#issuecomment-1069035654
  get eTimeType(): typeof ETimeType {
    return ETimeType;
  }

  addSituation(
    situation: ISituationAPIResponse = null,
    value: number | string = this.type === ETimeType.day ? 0 : '00:00',
    grantedDaysValue: number = null,
    emitEvent: boolean = true
  ): void {
    const situationGroup = this.formBuilder.group({
      situation: [ situation ],
      duration: [ value ],
      granted_days: [ grantedDaysValue ]
    });

    if (this.form.disabled) {
      situationGroup.disable();
    }

    situationGroup.get('situation').valueChanges
      .subscribe((groupSituation: ISituationAPIResponse) => {
        const grantedDays = situationGroup.get('granted_days');

        if (groupSituation?.granted_days_required) {
          grantedDays.setValidators([
            Validators.min(0),
            Validators.required
          ]);
        } else {
          grantedDays.clearValidators();
        }
      });

    this.situationsArray.push(
      situationGroup,
      { emitEvent: false }
    );

    this.changeDetectorRef.detectChanges();

    setTimeout(() => {
      if (this.disabled) {
        this.setDisabled(emitEvent);
      }

      if (this.required) {
        this.setValidator(emitEvent);
      }

      if (this.forceTouch) {
        this.setForceTouch(emitEvent);
      }
    });
  }

  compareSituations(
    previousSituation: ISituationAPIResponse,
    nextSituation: ISituationAPIResponse
  ): boolean {
    return previousSituation?.id === nextSituation?.id;
  }

  writeValue(value: ISituation[]): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}
  onChange(value: ISituation[]): void {}
  onTouch(): void {}

  private intervalToDuration(
    initialDate: string,
    endDate: string
  ): ITime {
    const minutes = Math.max(differenceInMinutes(
      new Date(endDate),
      new Date(initialDate)
    ));

    return this.buildTime(minutes);
  }

  private buildTime(minutes: number): ITime {
    const hours = Math.floor(minutes / 60).toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false
      }
    ),

     minutesLeft = (minutes % 60).toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false
      }
    );

    return `${hours}:${minutesLeft}` as ITime;
  }

  private subtractTimes(time1: ITime, time2: ITime): string {
    const minutes1 = this.timeToMinutes(time1),
     minutes2 = this.timeToMinutes(time2),
     difference = Math.max(minutes1 - minutes2, 0);

    return this.buildTime(difference);
  }

  private timeToMinutes(time: ITime): number {
    const hours = parseInt(time.substring(0, 2), 10) * 60,
     minutes = parseInt(time.substring(3, 5), 10);

    return !time ? 0 : hours + minutes;
  }

  private setRules(
    { initialDate, endDate }: IInterval, emitEvent: boolean
  ): void {
    this.initialDate = setISOStringWithoutTimezone(initialDate);
    this.endDate = setISOStringWithoutTimezone(endDate);

    let type;
    const hours = differenceInHours(
      endDate, initialDate
    ),

     exactlyDays = (hours % 24) === 0,
     moreThanOneDay = (hours / 24) > 0;

    if (exactlyDays && moreThanOneDay) {
      type = ETimeType.day;
      this.maxDays = hours / 24;
    } else {
      type = ETimeType.hour;
      this.maxTime = this.intervalToDuration(
        this.initialDate, this.endDate
      );
    }

    type === this.type && this.situationsArray.controls.length ?
      this.updateValues() :
      this.buildSituations(undefined, emitEvent);

    this.type = type;
    this.dateFormat = Utils.dateFormat(
      this.initialDate, this.endDate
    );
  }

  private buildSituations(
    situations: ISituation[] = undefined, emitEvent: boolean
  ): void {
    this.situationsArray.clear({ emitEvent: false });

    if (situations?.length) {
      situations.forEach((
        situation: ISituation, i: number
      ) => {
        if (this.type === ETimeType.day) {
          let days = differenceInDays(
            new Date(situation.end_date),
            new Date(situation.start_date)
          );

          if (!days && i === 0) {
            days = this.maxDays;
          }

          this.addSituation(
            situation,
            days,
            situation.granted_days,
            emitEvent
          );
        } else {
          let time = this.intervalToDuration(
            situation.start_date,
            situation.end_date
          );

          if (time === '00:00' && i === 0) {
            time = this.maxTime;
          }

          this.addSituation(situation, time, null, emitEvent);
        }
      });
    } else {
      this.addSituation(
        null,
        this.type === ETimeType.day ? this.maxDays : this.maxTime,
        null,
        emitEvent
      );
    }
  }

  private syncValues(): void {
    let newValue;

    if (this.situationsArray.controls.length < 2) {
      newValue =
        this.type === ETimeType.day ?
          this.maxDays :
          this.maxTime;
    } else {
      const firstValue = this.situationsArray
        .controls[0]
        .get('duration').value;

      newValue =
        this.type === ETimeType.day ?
          this.maxDays - firstValue :
          this.subtractTimes(this.maxTime, firstValue);
    }

    this.situationsArray
      .controls[this.situationsArray.controls.length - 1]
      .get('duration')
      .setValue(newValue, { emitEvent: false });
  }

  private updateValues(): void {
    this.syncValues();
    this.onChange(
      this.empty ?
      null :
      this.situationResults()
    );
  }

  private situationResults(): ISituation[] {
    const situations = [];

    this.situationsArray.value
      .filter((value: ISituationForm) => !!value.situation?.id)
      .forEach((value: ISituationForm, i: number) => {
        let initialDate = setDateWithoutTimezone(this.initialDate),
         endDate = setDateWithoutTimezone(this.endDate);

        if (value?.duration) {
          if (this.type === ETimeType.day) {
            if (i > 0) {
              initialDate = setDateWithoutTimezone(situations[i - 1].end_date);
            }

            if (i < (this.situationsArray.value.length - 1)) {
              endDate = addDays(initialDate, value.duration as number);
            }
          } else {
            if (i > 0) {
              initialDate = setDateWithoutTimezone(situations[i - 1].end_date);
            }

            if (i < (this.situationsArray.value.length - 1)) {
              const minutes = this.timeToMinutes(value.duration as ITime);
              endDate = addMinutes(initialDate, minutes);
            }
          }
        }

        situations.push({
          id: value?.situation?.id,
          code: value?.situation?.code,
          name: value?.situation?.name,
          start_date: setISOStringWithoutTimezone(initialDate),
          end_date: setISOStringWithoutTimezone(endDate),
          granted_days: value?.granted_days
        });
      });

    return situations;
  }

  private setAddRelease(): void {
    this.addReleased =
      this.situationsArray.controls.length < this.dataSource?.length;
  }

  private setDisabled(emitEvent: boolean): void {
    this.form[this.disabled ? 'disable' : 'enable']({ emitEvent });
  }

  private setValidator(emitEvent: boolean): void {
    if (this.required) {
      this.form.setValidators(SituationsValidator.required);
    } else {
      this.form.clearValidators();
    }

    this.form.updateValueAndValidity({ emitEvent });
  }

  private setForceTouch(emitEvent: boolean): void {
    if (this.forceTouch) {
      this.situationsArray.markAllAsTouched();
    } else {
      this.situationsArray.markAsPristine();
      this.situationsArray.markAsUntouched();
    }

    this.situationsArray.updateValueAndValidity({ emitEvent });
  }
}
