import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {API_USER_HMAC, ApiService, ListAPIResponse, USER_JWT, USER_PROFILE, USER_URL} from '../core/api.service';
import {Permissions, User, IUserIDs} from '../shared/model/user';
import {UserPayload} from '../shared/model/user-company.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private api: ApiService,
  ) {}

  getUserHmacHash(app: string): Observable<string> {
    return this.api.get<{hash: string}>(API_USER_HMAC(app)).pipe(
      map(result => result?.hash)
    );
  }

  getUser(userId: string, companyId: string): Observable<User> {
    if (!companyId) {
      return of(null);
    }

    return this.api.get<User>(USER_URL(companyId, userId))
      .pipe(map((u) => new User(u)));
  }

  getCompleteProfile(companyId: string): Observable<User> {
    if (!companyId) {
      return of(null);
    }

    return this.api.get<User>(USER_JWT(companyId))
      .pipe(
        map((u) => new User(u)),
      );
  }

  getProfile(): Observable<User> {
    return this.api.get(USER_PROFILE)
      .pipe(map((user) => new User(user)));
  }

  removeUser(userId: string, companyId: string): Observable<User> {
    return this.api.delete<User>(USER_URL(companyId, userId));
  }

  getUsers(
    companyId: string,
    objPerPage: number,
    page: number,
    query?: string,
    roles?: string[]
  ): Observable<ListAPIResponse<User>> {
    let params = new HttpParams()
      .set('obj_per_page', objPerPage)
      .set('page', page);

    if (!!query) {
      params = params.set('query', query);
    }

    if (!!roles?.length) {
      roles.forEach((role: string) => params = params.append('roles', role));
    }

    return this.api.get<ListAPIResponse<User>>(USER_URL(companyId), {params});
  }

  createUser(payload: UserPayload, companyId: string): Observable<IUserIDs> {
    return this.api.post(USER_URL(companyId), payload);
  }

  updateUser(id: string, payload: Omit<UserPayload, 'email'>, companyId: string): Observable<{}> {
    return this.api.put(`${USER_URL(companyId)}/${id}`, payload);
  }

  // TODO support resources
  hasPermissions(permissions: string[], userPermissions: Permissions): boolean {
    return permissions?.every(required => !!userPermissions?.permissions[required]);
  }
}
