import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export interface SimpleDialogData {
  title: string;
  titleStyle?: {[key: string]: string | number};
  content?: string;
  contentStyle?: {[key: string]: string | number};
  noButton?: string;
  yesButton?: string;
  color?: 'primary' | 'accent';
  showInput?: boolean;
  requireInput?: boolean;
  inputType?: 'textarea' | 'input' | 'email';
  inputLabel?: string;
}

export interface SimpleDialogResult {
  action: boolean;
  message?: string;
}

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent {
  inputValue = '';

  public static open(dialog: MatDialog, data: SimpleDialogData, config?: MatDialogConfig): MatDialogRef<SimpleDialogComponent, SimpleDialogResult> {
    const c: MatDialogConfig = config || {};
    c.panelClass = 'simple-dialog';
    c.maxWidth = 'calc(100vw - 32px)';
    c.data = data;
    return dialog.open(SimpleDialogComponent, c);
  }

  constructor(public dialogRef: MatDialogRef<SimpleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SimpleDialogData) {}

  onOkClick() {
    this.dialogRef.close({action: true, message: this.inputValue});
  }

  onNoClicked() {
    this.dialogRef.close(null);
  }
}
