import {NgxLoggerLevel, NGXLoggerMonitor, INGXLoggerMetadata} from 'ngx-logger';
import * as Sentry from '@sentry/angular';
import {Analytics} from '../services/analytics';

export class CustomLogMonitor implements NGXLoggerMonitor {
  constructor(private analytics: Analytics) {
  }
  onLog(log: INGXLoggerMetadata): void {
    if (log?.level >= NgxLoggerLevel.ERROR) {
      Sentry?.captureMessage(log?.message);
      this.analytics.error(log?.fileName, log?.message);
    }
  }
}
