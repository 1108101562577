import {Component, Input} from '@angular/core';

import { ISelectSubItem } from '../../entities/sidebar.interfaces';
import { SidebarService } from '../../sidebar.service';

@Component({
  selector: 'app-subitem-button',
  templateUrl: './subitem-button.component.html',
  styleUrls: ['./subitem-button.component.scss']
})
export class SubItemButtonComponent {
  @Input() subItem: ISelectSubItem;
  @Input() companyId: string;

  constructor(public sidebarService: SidebarService) {}
}
