import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  IPill,
  IPillOutput
} from './entities/pills.interface';

@Component({
  selector: 'app-pills',
  templateUrl: './pills.component.html'
})
export class PillsComponent {
  @Input() multiple: boolean;

  @Output() changed: EventEmitter<IPillOutput[]> = new EventEmitter<IPillOutput[]>();

  private _pills: IPill[];

  @Input()
  get pills(): IPill[] {
    return this._pills;
  }
  set pills(pills: IPill[]) {
    this._pills = pills;
  };

  setPills(i: number): void {
    if (!this.multiple) {
      this.pills.forEach((pill: IPill) => pill.selected = false);
      this.pills[i].selected = true;
    } else {
      this.pills[i].selected = !this.pills[i].selected;
    }

    const selected = this.pills.filter((pill: IPill) => pill.selected);
    const mapped = selected.map(({ id, name }: IPill) => ({ id, name }));

    this.changed.emit(mapped);
  }
}
