import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';

import {SharedModule} from '../../shared/shared.module';

import {NotificationItemModule} from '../notification-item/notification-item.module';
import {NotificationPopupComponent} from './notification-popup.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    SharedModule,
    NotificationItemModule
  ],
  declarations: [
    NotificationPopupComponent
  ],
  exports: [
    NotificationPopupComponent
  ]
})

export class NotificationPopupModule { }
