import {Component, Input} from '@angular/core';

import * as Notification from '../../shared/model/notification';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})

export class NotificationItemComponent {
  public _notification = {} as Notification.Interface;
  public message = '';

  @Input()
  set notification(n: Notification.Interface) {
    this._notification = n;
    this.setMessage();
  }

  setMessage() {
    let statusLabel;
    switch (this._notification.status) {
      case Notification.StatusEnum.invalid:
        statusLabel = 'recusado';
        break;
      case Notification.StatusEnum.valid:
        statusLabel = 'aprovado';
        break;
      default:
        statusLabel = '';
    }
    const prefix = this._notification.errors === 1 ? 'Ocorreu erro de sincronização' : 'Ocorreram erros de sincronização';
    this.message = `${prefix} em <strong>${this._notification.errors}</strong> atestado <strong>${statusLabel}</strong>`;
  }
}
