<h2 mat-dialog-title class="position-relative" [ngStyle]="data.titleStyle">
  {{data.title}}
  <button
    mat-icon-button
    mat-dialog-close
    class="small-circle-button"
  >
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div class="small-form-field">
  <div [ngStyle]="data.contentStyle">{{data.content}}</div>
  <mat-form-field appearance="outline" *ngIf="data.showInput" class="w-100" [ngClass]="{ 'pt-1 mt-3': data.content && data.showInput }">
    <mat-label>{{data.inputLabel}}</mat-label>
    <ng-container [ngSwitch]="data.inputType">
      <input matInput *ngSwitchCase="'input'" [(ngModel)]="inputValue"/>
      <input matInput type="email" *ngSwitchCase="'email'" [(ngModel)]="inputValue">
      <textarea matInput rows="3" *ngSwitchDefault
                [(ngModel)]="inputValue">
      </textarea>
    </ng-container>
  </mat-form-field>
</div>
<mat-dialog-actions align="end">
  <button mat-button
          type="button"
          [color]="!!data?.color ? data.color : 'primary'"
          *ngIf="!!data.noButton"
          (click)="onNoClicked()" cdkFocusInitial>{{data.noButton}}</button>
  <button mat-button
          type="button"
          [color]="!!data?.color ? data.color : 'primary'"
          *ngIf="!!data.yesButton"
          [disabled]="data.showInput && data.requireInput && !inputValue.length"
          (click)="onOkClick()"
  >{{data.yesButton}}</button>
</mat-dialog-actions>
