import { EDocumentType } from './documents.enums';

export const TYPES = {
  occupational: {
    categoryId: EDocumentType.occupational,
    label: 'ocupacional'
  },
  childcareAssistance: {
    categoryId: EDocumentType.childcareAssistance,
    label: 'auxílio creche'
  }
};
