<app-dialog-crude
  [confirmText]="'RECUSAR'"
  [confirmDisabled]="!reprovation?.message"
  (onCancel)="matDialogRef.close(null)"
  (onConfirm)="matDialogRef.close(reprovationConfirmation)"
>
  <div content>
    <h2 mat-dialog-title class="mt-0">
      Recusar documento
    </h2>
    <app-reprovation (onChange)="reprovationChanged($event)"></app-reprovation>
  </div>
</app-dialog-crude>
