import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeHtml, SafeUrl} from '@angular/platform-browser';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';
import {
  intervalToDuration,
  formatDuration
} from 'date-fns';
import {ptBR} from 'date-fns/locale';

import {Utils} from './utils';
import {totalHours} from '../utils/time';
import {IExtendedSickNote, TravelMinutes} from '../model/sick_note';
import {Person} from '../model/person';
import {
  ResponseReason,
  ResponseSubReason
} from 'src/app/shared/model/refusal-reasons.model';
import {PeopleService} from '../../services/people.service';
import {SickNoteService} from '../../services/sick-note.service';
import {pendingValidation, SickNoteStatusType} from 'src/app/shared/model/sick_note';

@Pipe({name: 'cpfFormat', pure: true})
export class CpfFormatPipe implements PipeTransform {
  transform(cpf: string): string {
    if (!cpf) {
      return null;
    }

    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');

    return cpf;
  }
}

@Pipe({name: 'formatDocument', pure: true})
export class FormatDocumentPipe implements PipeTransform {
  transform(document_id: string, document_type: string): string {
    if (!document_id || !document_type) {
      return '';
    }
    if (document_type.toUpperCase() === 'CPF') {
      return new CpfFormatPipe().transform(document_id);
    } else {
      return 'ID: ' + document_id;
    }
  }
}

@Pipe({name: 'cnpjFormat', pure: true})
export class CnpjFormatPipe implements PipeTransform {

  transform(cnpj: string): string {
    return Utils.formatCNPJ(cnpj);
  }
}

@Pipe({name: 'identityFormat', pure: true})
export class IdentityFormatPipe implements PipeTransform {

  transform(identity: string, type: string): string {
    switch(type.toLocaleLowerCase()) {
      case 'cpf':
        return new CpfFormatPipe().transform(identity);
      case 'cnpj':
        return new CnpjFormatPipe().transform(identity);
      default:
        return identity;
    }
  }
}

@Pipe({name: 'nameInitials', pure: true})
export class NameInitialsPipe implements PipeTransform {

  transform(name: string): string {
    return PeopleService.getNameInitials(name);
  }
}

@Pipe({name: 'format_date', pure: true})
export class FormatDate implements PipeTransform {

  transform(date_from: moment.Moment, format: string): string {
    return date_from?.format(format) || '00:00';
  }
}

@Pipe({name: 'date_diff', pure: true})
export class DateDiffPipe implements PipeTransform {

  transform(from: string, to: string, approximate = false): {
    days: number;
    hours: number;
    minutes: number;
    text: string;
  } {
    return Utils.dateDiff(from, to, approximate);
  }
}

@Pipe({name: 'secure'})
export class SecurePipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  transform(url): Observable<SafeUrl> {
    if (url) {
      return this.http
        .get(url, {responseType: 'blob'}).pipe(
          map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)))
        );
    } else {
      return null;
    }
  }
}

@Pipe({name: 'some', pure: true})
export class SomePipe implements PipeTransform {

  transform(values: any[], key: string): boolean {
    return (values || []).map(v => v[key]).some(i => !!i);
  }
}

@Pipe({name: 'map', pure: true})
export class MapPipe implements PipeTransform {

  transform(values: any[], key: string): any[] {
    return (values || []).map(v => v[key]);
  }
}

@Pipe({name: 'sick_note_corrupted'})
export class SickNoteCorruptedPipe implements PipeTransform {

  constructor() { }

  transform(sickNote: IExtendedSickNote): boolean | Observable<boolean> {
    return !!sickNote.details?.issues && Object.values(sickNote.details.issues).some(v => !!v);
  }
}

@Pipe({name: 'has'})
export class HasPipe implements PipeTransform {
  transform(arr: any[] | Set<any>, searchValue: string, searchKey?: string): boolean {
    if (arr instanceof Set) {
      return arr.has(searchValue);
    }
    return arr.some(item => searchKey ? item[searchKey] === searchValue : item === searchValue);
  }
}

@Pipe({name: 'toString'})
export class StringPipe implements PipeTransform {
  transform(value: any): string {
    return String(value);
  }
}

@Pipe({name: 'limiter'})
export class LimiterPipe implements PipeTransform {
  transform(value: string, limiter: number): string {
    return value.slice(0, limiter) + (value.length > limiter ? `...` : '');
  }
}

@Pipe({name: 'find', pure: true})
export class FindPipe implements PipeTransform {

  transform<T>(values: T[], key: string, value: any): T {
    return (values || []).find(v => v[key] === value);
  }
}

@Pipe({name: 'age', pure: true})
export class AgePipe implements PipeTransform {
  transform(birthdate): string {
    const yearOld: number = moment().diff(birthdate, 'years');
    if (yearOld) {
return `${yearOld} ano${yearOld > 1 ? 's' : ''}`;
}

    const monthsOld: number = moment().diff(birthdate, 'months');
    if (monthsOld) {
return `${monthsOld} m${monthsOld > 1 ? 'eses' : 'ês'}`;
}

    const daysOld: number = moment().diff(birthdate, 'months');
    if (daysOld) {
return `${daysOld} dia${daysOld > 1 ? 's' : ''}`;
}

    return '';
  }
}
@Pipe({name: 'routerBuilder', pure: true})
export class RouterBuilderPipe implements PipeTransform {
  transform(routes: string[], companyId: string): string[] {
    return ['/c/', companyId || 'default', ...routes || []];
  }
}

@Pipe({name: 'sequencer', pure: true})
export class SequencerPipe implements PipeTransform {
  transform(collection: any[], property: string, junction?: string): string {
    return collection?.map((item) => item[property])?.join(junction || ', ') || '';
  }
}

@Pipe({name: 'toHour', pure: true})
export class ToHourPipe implements PipeTransform {
  transform(minutes: number): string {
    return totalHours(minutes);
  }
}

@Pipe({name: 'hasTravelTimePipe', pure: true})
export class HasTravelTimePipePipe implements PipeTransform {
  transform(travelTime: TravelMinutes): boolean {
    return travelTime?.before === 0
      || travelTime?.before >= 1
      || travelTime?.after === 0
      || travelTime?.after >= 1;
  }
}

@Pipe({name: 'isPersonActive', pure: true})
export class PersonActivePipe implements PipeTransform {
  transform(person: Person): boolean {
    return person?.active !== false && !person?.dismissal_date;
  }
}

@Pipe({name: 'canEdit', pure: true})
export class CanEditPipe implements PipeTransform {
  constructor(private sickNoteService: SickNoteService) {
  }
  transform(sickNote: IExtendedSickNote): boolean {
    return this.sickNoteService.userCanEdit(sickNote);
  }
}

@Pipe({name: 'amount', pure: true})
export class AmountPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(collection: any[], toShow = 2): SafeHtml  {
    const amount = collection.slice(0, toShow);
    let sufix = '';

    if (collection.length > amount.length) {
      sufix = ` <strong>+${collection.length - amount.length}</strong>`;
    }

    return this.sanitizer.bypassSecurityTrustHtml(
      `${amount.join(', ') || ''}${sufix}`
    );
  }
}

@Pipe({name: 'pendingStatus'})
export class PendingStatus implements PipeTransform {
  transform(status: SickNoteStatusType): boolean {
    return pendingValidation(status);
  }
}

@Pipe({name: 'refusalReasonCategories', pure: true})
export class RefusalReasonCategoriesPipe implements PipeTransform {
  transform(reasonIds: number[], categories: ResponseReason[]): ResponseSubReason[] {
    return reasonIds?.map((reasonId: number) => {
      let categoriesFound: ResponseSubReason;

      categories?.some(({ reasons }: ResponseReason) => {
        const subCategoryFound = reasons.find((
          { id }: ResponseSubReason
        ) => reasonId === id);

        if (subCategoryFound) {
          categoriesFound = subCategoryFound;
        };

        return !!categoriesFound;
      });

      return categoriesFound;
    });
  }
}

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}


@Pipe({ name: 'completeDifferenceDate' })
export class CompleteDifferenceDate implements PipeTransform {
  transform(startDate, endDate?) {
    if (!startDate) {
      return '';
    }

    const duration = intervalToDuration({
      start: new Date(startDate),
      end: endDate ? new Date(endDate) : new Date(),
    });

    return formatDuration(duration, {
      format: ['years', 'months', 'days'],
      zero: true,
      locale: ptBR
    });
  }
}
