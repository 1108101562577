import {Component, ChangeDetectorRef} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

import {Category, SubmissionReprovation} from '../../shared/model/reprovation';
import {ResponseSubReason, ResponseReason} from '../../shared/model/refusal-reasons.model';

export interface DialogReprovationResult extends SubmissionReprovation {
   action: boolean;
}

@Component({
  selector: 'app-dialog-reprovation',
  templateUrl: './dialog-reprovation.component.html',
  styleUrls: ['./dialog-reprovation.component.scss']
})
export class DialogReprovationComponent {
  reprovation: SubmissionReprovation;

  public static open(dialog: MatDialog): MatDialogRef<DialogReprovationComponent, DialogReprovationResult> {
    const config: MatDialogConfig = {
      width: '550px',
      maxWidth: 'calc(100% - 32px)',
      maxHeight: '90%',
      height: 'auto',
      autoFocus: false,
      closeOnNavigation: false,
      disableClose: true
    };

    return dialog.open(DialogReprovationComponent, config);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public matDialogRef: MatDialogRef<DialogReprovationComponent>
  ) {}

  get reprovationConfirmation(): DialogReprovationResult {
    return {
      ...this.reprovation,
      action: true
    };
  }

  reprovationChanged(reprovation: SubmissionReprovation): void {
    this.reprovation = reprovation;
    this.changeDetectorRef.detectChanges();
  }

  private setCategories(items: ResponseReason[] | ResponseSubReason[]): Category[] {
    return items.map((reason: ResponseReason | ResponseSubReason) => {
      const children = (reason as ResponseReason)?.reasons;
      const category: Category = {
        id: reason.id,
        name: reason.label
      };

      if (!!children) {
        category.children = this.setCategories(children);
      }

      return category;
    });
  }
}
