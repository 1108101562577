import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams
} from '@angular/common/http';

import { Observable } from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';

import { SessionService } from 'src/app/core/session.service';
import { ListAPIResponse } from 'src/app/core/api.service';
import { ISituationAPIResponse } from './entities/situations.interfaces';

export const situationsAPI = (companyId: string) => (
  `/v4/companies/${companyId}/situations`
);

@Injectable()
export class FormSituationsService {
  constructor(
    private http: HttpClient,
    private session: SessionService
  ) {}

  get(typeId?: number): Observable<ISituationAPIResponse[]> {
    let params = new HttpParams()
      .set('page', 1)
      .set('obj_per_page', 500);

    if (!!typeId) {
      params = params.set('type_id', typeId);
    }

    return this.http.get(
      situationsAPI(this.session.currentCompanyId),
      { params }
    ).pipe(
      map(({ items }: ListAPIResponse<ISituationAPIResponse>) => (
        items.sort((
          previousSituation: ISituationAPIResponse,
          nextSituation: ISituationAPIResponse
        ) => (
          previousSituation.code
            .localeCompare(nextSituation.code, 'en', { numeric: true })
        ))
      ))
    );
  }
}
