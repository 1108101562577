import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  retry
} from 'rxjs/operators';

import { IResponseItems } from 'src/app/shared/interfaces/response.interfaces';
import {
  ResponseReason,
  Reason
} from 'src/app/shared/model/refusal-reasons.model';
import {
  API_REASONS_MESSAGES,
  ApiService,
  CATEGORIES
} from 'src/app/core/api.service';
import { SessionService } from 'src/app/core/session.service';

@Injectable({
  providedIn: 'root',
})
export class RefusalReasonService {
  constructor(
    private apiService: ApiService,
    private sessionService: SessionService
  ) {}

  getCategories(): Observable<ResponseReason[]> {
    return this.apiService.get(CATEGORIES)
      .pipe(map(({ items }: IResponseItems<ResponseReason>) => items));
  }

  getList(reasonIds?: number[]): Observable<Reason[]> {
    const reason_ids = reasonIds?.map((id) => id.toString()) || [];

    return this.sessionService.currentCompanyId$.pipe(
      mergeMap((companyId) => (
        this.apiService.get(
          API_REASONS_MESSAGES(companyId), { params: { reason_ids } }
        ).pipe(
          retry(2),
          map(({ items }: { items: Reason[] }) => items),
          catchError(() => [])
        )
      ))
    );
  }

  add(reason: Reason): Observable<{ id: number }> {
    return this.sessionService.currentCompanyId$.pipe(
      mergeMap((companyId: string) => this.apiService.post<{ id: number }>(
          API_REASONS_MESSAGES(companyId),
          reason
        ))
    );
  }

  update(
    reasonId: string,
    changes: Partial<Reason>
  ): Observable<{ id: number }> {
    return this.sessionService.currentCompanyId$.pipe(
      mergeMap((companyId: string) => {
        const api: string = API_REASONS_MESSAGES(companyId, reasonId);

        return this.apiService.put<{ id: number }>(api, changes);
      })
    );
  }

  delete(reasonId: string): Observable<null> {
    return this.sessionService.currentCompanyId$.pipe(
      mergeMap((companyId: string) => {
        const api: string = API_REASONS_MESSAGES(companyId, reasonId);

        return this.apiService.delete<null>(api);
      })
    );
  }
}
