import {RouteReuseStrategy} from '@angular/router/';
import {ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import {LRU} from '../../shared/utils/lru';
import {Injectable} from '@angular/core';

@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  routesToCache: string[] = ['sick-notes-list'];
  storedRouteHandles = new LRU<DetachedRouteHandle>(5); // avoid using too much memory
  companyRegex = /\/c\/(.+)\/.*/;

  private getPath(route: ActivatedRouteSnapshot): string {
    if (!!route.routeConfig) {
      const path = route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
      const parts = this.companyRegex.exec(path);
      return !!parts && parts.length > 0 ? `${parts[1]}/${route.data['shouldReuse']}` : '';
    }
    return '';
  }

  // Decides if the route should be stored
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.routesToCache.indexOf(route.data['shouldReuse']) > -1;
  }

  // Store the information for the route we're destructing
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getPath(route), handle);
  }

  // Return true if we have a stored route object for the next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.storedRouteHandles.has(this.getPath(route));
  }

  // If we returned true in shouldAttach(), now return the actual route data for restoration
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(this.getPath(route));
  }

  // Reuse the route if we're going to and from the same route
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
