import { SortDirection } from '@angular/material/sort';
import { ICD, Professional } from 'src/app/shared/interfaces/core.interface';

import moment from 'moment';

import { ListAPIResponse } from 'src/app/core/api.service';
import { DocumentType } from 'src/app/shared/model/document-type';
import { ResponseSubReason } from 'src/app/shared/model/refusal-reasons.model';
import { Warning } from 'src/app/shared/model/warning';
import * as File from './file.model';
import { Person } from './person';
import { ProfessionalDetailsIf } from './professional';
import { Team } from './team';
import { IISODateTime } from '../interfaces/date.interfaces';
import { ILocation, ILocationGroup } from '../interfaces/location.interface';

import {
  IInterval,
  IProfessional,
  ISituation,
} from 'src/app/components/form-components/entities/form-components.interfaces';

export type SickNoteStatusType =
  | 'pending'
  | 'filled'
  | 'verified'
  | 'warning'
  | 'valid'
  | 'invalid'
  | 'triage';

export const SickNoteStatus: {
  [key in SickNoteStatusType]: {
    key: SickNoteStatusType;
    label: string;
    icon: string;
  };
} = {
  pending: {
    key: 'pending',
    label: 'Recebido',
    icon: 'schedule',
  },
  filled: {
    key: 'filled',
    label: 'Processando',
    icon: 'schedule',
  },
  verified: {
    key: 'verified',
    label: 'Em análise',
    icon: 'schedule',
  },
  warning: {
    key: 'warning',
    label: 'Em análise',
    icon: 'schedule',
  },
  valid: {
    key: 'valid',
    label: 'Aprovado',
    icon: 'thumb_up',
  },
  invalid: {
    key: 'invalid',
    label: 'Recusado',
    icon: 'thumb_down',
  },
  triage: {
    key: 'triage',
    label: 'Ambulatório',
    icon: 'local_hospital',
  },
} as const;

export interface SickNoteSync {
  success: boolean;
  error?: {
    code?: string;
    message: string;
  };
  vendor: string;
}

export interface SickNoteType {
  children: SickNoteType[];
  code: string;
  description: string;
  document_type: DocumentType;
  esocial_id: any;
  id: number;
  name: string;
  parent_id: number;
  visible: boolean;
  visible_b2c: boolean;
  situations: Situation[];
  active: boolean;
}

export interface ISickNoteFields {
  travel_minutes?: TravelMinutes;
  appointment_date?: string;
  expiration_date?: string;
  icds: ICD[];
  image_path?: string;
  link?: string;
  internal_notes?: string;
  public_notes?: {
    message?: string;
    reasons?: string[];
  };
  person_id: string;
  premises?: string;
  reasons?: ResponseSubReason[];
  secret?: string;
  professional?: Professional;
  type_id: number;
  vaccine?: {
    dose: string;
    name: string;
  };
  files: File.Data[];
  verified: boolean;
  external_id: string;
}

export interface Metadata {
  created_at?: string;
  updated_at?: string;
  registered_by_uid: string;
  updated_by_uid: string;
  updated_by?: {
    id: number;
    name: string;
    email: string;
    user_id: string;
  };
  source: string;
}

export interface IExtendedSickNote {
  id: string;
  company_id: string;
  status: SickNoteStatusType;
  short_id: string;
  details: {
    alerts: {
      [key: string]: boolean;
    };
    issues: {
      [key: string]: any;
    };
  };
  fields: ISickNoteFields;
  extras: {
    document_type: DocumentType;
    location: Location;
    person: Person;
    image_url: string;
    type: SickNoteType;
    metadata: Metadata;
    status_reasons?: StatusReason[];
    professional: ProfessionalDetailsIf;
    related: Related;
    situations: Situation[];
    team: Team;
    syncs: SickNoteSync[];
  };
}

export type Related = Omit<IExtendedSickNote, 'extras' | 'details'> & {
  extras: {
    image_url: string;
    labor_union: {
      code: string;
      id: number;
      name: string;
    };
    location: ILocation;
    location_group: ILocationGroup;
    metadata: {
      source: 'employee' | 'manual' | 'batch';
      registered_by_uid: string;
      updated_by_uid: string;
      created_at: IISODateTime;
      updated_at: IISODateTime;
      updated_by: {
        id: number;
        email: string;
        name: string;
        user_id: string;
      };
    };
    person: Pick<
      Person,
      | 'id'
      | 'code'
      | 'cpf'
      | 'name'
      | 'document_id'
      | 'document_type'
      | 'social_name'
      | 'active'
      | 'status'
    >;
    professional: {
      id: number;
      name: string;
      state: string;
      status: string;
      type: string;
      specialty: string[];
      created_at: IISODateTime;
    };
    status_reasons: {
      description: string;
      id: string;
      public: boolean;
      status: string;
      threshold: string;
      value: string;
    }[];
    /**
     * @deprecated The method should not be used
     */
    situation: Pick<Situation, 'id' | 'code' | 'name'>;
    situations: (Situation & { type: string })[];
    sync: SickNoteSync;
    type: {
      id: number;
      code: string;
      name: string;
    };
    document_type: {
      id: number;
      code: string;
      label: string;
      family: string;
      social_security: boolean;
    };
    team: Team;
    history: {
      status: string;
      created_at: string;
      reasons: {
        id: string;
        status: string;
        threshold: string;
        value: string;
        public: boolean;
      }[];
      registered_by: {
        id: number;
        user_id: string;
        email: string;
        type: string;
        name: string;
      };
    };
  };
  details: {
    alerts: {
      is_late: boolean;
    };
    issues: { [key: string]: string };
    field_status: { [key: string]: 'pending' | 'warning' | 'verified' };
  };
};

export class TravelMinutes {
  before: number;
  after: number;
}

export class SickNoteBody {
  person_id: string;
  icds: string[];
  premises?: string;
  internal_notes?: string;
  type_id?: number;
  files?: string[] | [];
  issues?: { [key: string]: string };
  link?: string;
  secret?: string;
  public_notes?: PublicNotes;
  status?: SickNoteStatusType;
  reason_ids?: number[];
  related?: string;
  reason_rais_id?: number;
  vaccine_dose: number;
  vaccine_name: string;
  travel_minutes_before: number;
  travel_minutes_after: number;

  //TODO dynamic form - to remove
  appointment_date?: moment.Moment;
  expiration_date?: moment.Moment;
  professional?: Professional;
  situations: Situation[];

  //TODO dynamic form - added
  dynamicAppointmentDate: Date;
  dynamicInterval: IInterval;
  dynamicProfessional: IProfessional;
  dynamicSituations: ISituation[];
}

export interface PublicNotes {
  message: string;
  reasons: string[];
}

export interface StatusReason {
  id: string;
  status: string;
  threshold: string | number;
  value: string | number;
  description: string;
  public: boolean;
}

export interface Situation {
  id: number;
  code?: string;
  name?: string;
  granted_days_required?: boolean;
  granted_days?: number;
  start_date?: string;
  end_date?: string;
}

export interface RaisReason {
  code: string;
  id: number;
  label: string;
}

export const pendingStatus = ['verified', 'warning', 'triage'];
export function pendingValidation(status: SickNoteStatusType): boolean {
  return pendingStatus.includes(status);
}
export const inAnalysisStatus = ['pending', 'filled'];
export const processingStatus = ['filled'];

export interface WarningsAPIResponse extends ListAPIResponse {
  warnings: Warning[];
}

export class SickNotesAPIResponse implements ListAPIResponse {
  num_pages = 0;
  page = 0;
  total = 0;
  items: IExtendedSickNote[] = [];
  file?: {
    type: string;
    url: string;
  };
}

export class SickNotesV5APIResponse {
  items: IExtendedSickNote[] = [];
  file?: {
    type: string;
    url: string;
  };
}

export class SickNotesTypesAPIResponse implements ListAPIResponse {
  num_pages = 0;
  page = 0;
  sick_notes_types: SickNoteType[] = [];
  items: [];
  total = 0;
}

export type FieldDateType = 'appointment_date' | 'created_at' | 'updated_at';

export interface SickNoteChange {
  action: 'validate' | 'delete' | 'add' | 'update';
  sickNoteId: string;
  sickNoteStatus?: SickNoteStatusType;
  auto: boolean;
}

export interface SickNoteListRequest {
  direction?: 'next' | 'prev';
  first_item?: PageItem;
  last_item?: PageItem;
  obj_per_page?: number;
  filters?: any;
  verified?: boolean;
  /**
   * @deprecated
   */
  status?: string[];
  sort?: {
    field: string;
    order: SortDirection;
  }[];
}

export interface PageItem {
  id: string;
  appointment_date: string;
  created_at: string;
  updated_at: string;
}

export interface VaccineType {
  id: string;
  name: string;
  full_name: string;
}
