import {Component} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class ProfileSettingsComponent {
  constructor(private dialog: MatDialog) {}

  public close (): void {
    this.dialog.closeAll();
  }
}
