import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {SessionService} from '../core/session.service';

@Injectable({
  providedIn: 'root'
})
export class BetaGuard implements CanActivate {
  closecareRegex = /^[A-Za-z0-9._%+-]+@closecare\.com\.br$/;
  constructor(private session: SessionService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const restrictedHostname = window.location.hostname.includes('beta.closecare.com.br');
    return !restrictedHostname || this.session.loggedInUser$.pipe(
      take(1),
      map(user => {
        return this.closecareRegex.test(user?.email) || this.router.parseUrl('/restricted');
      })
    );
  }
}
