import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Action} from './company-container.component';

@Injectable({
  providedIn: 'root'
})
export class CompanyContainerService {

  actionEvent = new Subject<Action>();

  constructor() { }

  clickAction(action: Action) {
    this.actionEvent.next(action);
  }

  get actionClickEvent(): Observable<Action> {
    return this.actionEvent.asObservable();
  }
}
