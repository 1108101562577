import { Component, Input } from '@angular/core';

import { Company } from 'src/app/shared/model/company';

@Component({
  selector: 'app-company-content',
  templateUrl: './company-content.component.html',
  styleUrls: ['./company-content.component.scss']
})
export class CompanyContentComponent {
  @Input() company: Company;
  @Input() multipleCompanies: boolean;
  @Input() tabIndex: number;
}
