import {Person} from './person';

export interface InssExtras {
  ntep: boolean;
  total_leave_days: number;
  active_sick_note: {
    id: string;
    appointment_date: string;
    expiration_date: string;
    leave_days: number;
  };
  past_sick_notes: {
    ids: string[];
    leave_days_sum: number;
    related_leave_days: 2;
    uncategorized_leave_days: 1;
  };
  range_considered: {
    from: string;
    to: string;
  };
}

export class Warning {
  person: Person;
  groups: {
    [key: string]: {
      id: string;
      name: string;
      extras: InssExtras
    }
  };

  constructor(w?: Warning) {
    if (!!w) {
      Object.assign(this, w);
    }
  }

  get inss(): boolean {
    const inssGroup = this.groups.inss;
    return !!inssGroup && inssGroup.extras.total_leave_days > 15;
  }

  get ntep(): boolean {
    const inssGroup = this.groups.inss;
    return !!inssGroup && inssGroup.extras.ntep;
  }

  getExtras(groupId: string) {
    const group = this.groups[groupId];
    return !!group ? group.extras : undefined;
  }
}


