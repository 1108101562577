import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'app-item-content',
  templateUrl: './item-content.component.html'
})
export class ItemContentComponent {
  @Input() icon: string;
  @Input() notifications: number;
  @Input() title: string;
}
