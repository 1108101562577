import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HeaderModule} from '../header/header.module';

import {ContainerHeaderComponent} from './container-header.component';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule
  ],
  declarations: [
    ContainerHeaderComponent
  ],
  exports: [
    ContainerHeaderComponent
  ]
})

export class ContainerHeaderModule {}
