import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CanDeactivate } from '@angular/router';

import {
  Observable,
  of
} from 'rxjs';
import {
  map,
  tap
} from 'rxjs/operators';

import { ComponentCanDeactivate } from '../shared/component-can-deactivate/ComponentCanDeactivate';
import { SimpleDialogComponent } from '../components/simple-dialog/simple-dialog.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    if (
      typeof component.canDeactivate === 'function' &&
      !component.canDeactivate()
    ) {
      return SimpleDialogComponent.open(this.dialog, {
        title: 'Alterações não salvas',
        content: 'As alterações realizadas não foram salvas! O que você deseja fazer agora?',
        noButton: 'DESCARTAR',
        yesButton: 'CONTINUAR EDIÇÃO',
        color: 'primary'
      },
      { width: '400px' })
      .afterClosed()
      .pipe(
        map((result) => !result?.action),
        tap((continueEdition) => {
          if (continueEdition) {
            component.continueEdition();
          } else {
            component.discardChanges();
          }
        })
      );
    }

    return of(true);
  }
}
