import {APP_INITIALIZER, ErrorHandler, isDevMode, LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import {LayoutModule} from '@angular/cdk/layout';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {AngularFireAnalyticsModule, APP_NAME, APP_VERSION, CONFIG, DEBUG_MODE, UserTrackingService} from '@angular/fire/compat/analytics';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFirePerformanceModule} from '@angular/fire/compat/performance';
import {AngularFireRemoteConfigModule, DEFAULTS, SETTINGS} from '@angular/fire/compat/remote-config';
import {AngularFireStorage, AngularFireStorageModule} from '@angular/fire/compat/storage';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {Router, RouteReuseStrategy} from '@angular/router';

import * as Sentry from '@sentry/angular';
import {IntercomModule} from 'ng-intercom';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';

import {environment} from '../environments/environment';

import {SidebarModule} from './components/sidebar/sidebar.module';
import {ContainerHeaderModule} from './components/container-header/container-header.module';
import {HeaderModule} from './components/header/header.module';
import {NotificationsListModule} from './notifications/notifications-list/notifications-list.module';
import {SettingsDialogModule} from './profile-popup/settings/settings.module';
import {SharedModule} from './shared/shared.module';

import {SecurePipe} from './shared/utils/pipes';

import {AuthInterceptor} from './core/app.http-interceptor';
import {SentryErrorHandler} from './core/sentry-error-handler';
import {CompanyContainerComponent} from './company-container/company-container.component';
import {CompanyPickerComponent} from './company-picker/company-picker.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotificationPopupModule} from './notifications/notification-popup/notification-popup.module';
import {HomeComponent} from './pages/main-dashboard/home.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {RestrictedComponent} from './pages/restricted/restricted.component';
import {CacheRouteReuseStrategy} from './pages/sick-note/cache-route-reuse.strategy';
import {ProfilePopupComponent} from './profile-popup/profile-popup.component';
import {MatPaginatorIntlPtBr} from './shared/MatPaginatorIntlPtBr';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProfilePopupComponent,
    HomeComponent,
    SecurePipe,
    CompanyPickerComponent,
    CompanyContainerComponent,
    NotFoundComponent,
    RestrictedComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    AngularFireRemoteConfigModule,
    BrowserModule,
    SharedModule,
    AngularFireModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatCardModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    MatDividerModule,
    MatBadgeModule,
    MatSidenavModule,
    MatListModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
      enableSourceMaps: true
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    NotificationsListModule,
    NotificationPopupModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SidebarModule,
    ContainerHeaderModule,
    HeaderModule
  ],
  providers: [
    Title,
    AngularFireStorage,
    AngularFireAuthGuard,
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlPtBr
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MM YYYY',
        },
      }
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true} },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: CONFIG, useValue: {
        send_page_view: false,
    } },
    { provide: APP_NAME, useValue: environment.appName },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: UserTrackingService },
    { provide: DEBUG_MODE, useValue: false },
    { provide: DEFAULTS, useValue: { loginMode: 'email' } },
    {
      provide: SETTINGS,
      useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}
    },
    SettingsDialogModule
],
  exports: [
    SecurePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Optional() @SkipSelf() parentModule: AppModule,
              private afs: AngularFirestore) {
    const settings = { experimentalAutoDetectLongPolling: true, merge: true };
    afs.firestore.settings( settings );
  }
}
