import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { IItem } from '../../entities/sidebar.interfaces';
import { SidebarService } from '../../sidebar.service';

@Component({
  selector: 'app-item-button',
  templateUrl: './item-button.component.html',
  styleUrls: ['../menu.shared.scss'],
})
export class ItemButtonComponent {
  @Input() companyId: string;
  @Input() tabIndex: number;

  routerLink: string[];

  private _item: IItem;

  @Input()
  get item(): IItem {
    return this._item;
  }
  set item(item: IItem) {
    this._item = item;
    this.routerLink =
      item.children?.[0].children?.[0].routes ||
      item.children?.[0].routes ||
      item.routes;
  }

  constructor(
    public sidebarService: SidebarService,
    private router: Router,
  ) {}

  matchPath(path: string) {
    return this.router.url.split('/').includes(path);
  }
}
