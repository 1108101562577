import packageInfo from '../../package.json';

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyARk1Z9ENfAZ2VAaiatlHh9mmo_BneAaQE',
    authDomain: 'alpha.closecare.com.br',
    databaseURL: 'https://nustro-aaf4f.firebaseio.com',
    projectId: 'nustro-aaf4f',
    storageBucket: 'nustro-aaf4f.appspot.com',
    messagingSenderId: '1043673447407',
    appId: '1:1043673447407:web:d42ad2f374853088'
  },
  appName: 'B2B',
  recaptcha_v2: '6LeFu6cUAAAAAEgs9jsTzhD1BoYZFFExwt87HgXI',
  sick_notes_images_folder: 'sick_notes/images/',
  sick_notes_imports_folder: 'sick_notes/imports/',
  people_imports_folder: 'people/imports/',
  intercomAppId: 'pijdcech',
  sentryDsn: 'https://9db1adae602743eaaaf406b5398ffdd2@o373977.ingest.sentry.io/5191304',
  amplitudeApiKey: 'c65992aaef506e24cfd463b5caf77e91',
  version: packageInfo.version,
  name: packageInfo.name,
};

export const AUTHENTICATED_DOMAINS = [
  'localhost',
  'closecare.com.br',
  'nustro-aaf4f.appspot.com',
  'firebasestorage.googleapis.com',
  'esp-apis-v3-ydd63pv53q-uc.a.run.app'
];

export const CFM_API = 'https://portal.cfm.org.br/index.php';
export const METABASE_URL = 'https://metabase-postgres-dot-nustro-aaf4f.appspot.com';
export const CLOSECARE_SAML_PROVIDER_ID = 'saml.closecare-saml';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
