import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Permissions, User} from '../shared/model/user';
import {catchError, distinctUntilChanged, filter, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {ApiService, USER_PERMISSIONS_API} from './api.service';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public loggedInUser$: Observable<User> = new Observable<User>();
  private _permissions = new BehaviorSubject<Permissions>(new Permissions());
  public readonly permissions$ = this._permissions.asObservable();
  private _currentCompanyId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly currentCompanyId$ = this._currentCompanyId$.asObservable().pipe(distinctUntilChanged((a, b) => a === b));
  public currentCompanyId: string;
  public companyUser: User;

  constructor(private api: ApiService,
              private userService: UserService) {
    this.currentCompanyId$.subscribe((companyId) => {
      this.currentCompanyId = companyId;
    });

    this.currentCompanyId$.pipe(
      filter((companyId) => !!companyId),
      mergeMap((companyId) => this.userService.getCompleteProfile(companyId))
    ).subscribe((user) => this.companyUser = user);
  }

  get permissions(): Permissions {
    return this._permissions.value;
  }

  private updatePermissions(companyId: string): Observable<boolean> {
    return this.api.get<Permissions>(USER_PERMISSIONS_API('company', companyId))
      .pipe(
        map((permissions) => {
          this._permissions.next(permissions);
          return !!permissions?.roles?.company ? companyId : null;
        }),
        map((c) => companyId === c),
        catchError((e) => {
          console.error('updatePermissions', e);
          return of(false);
        })
      );
  }

  changeCurrentCompany(companyId: string): Observable<boolean> {
    if (companyId === this.currentCompanyId && !!this.permissions?.roles?.company) {
      return of(true);
    }
    return this.updatePermissions(companyId)
      .pipe(
        tap((valid) => {
          this._currentCompanyId$.next(valid ? companyId : null);
        }),
        catchError((e) => {
          console.error('ChangeCurrentCompany', e);
          return of(false);
        })
      );
  }
}
