import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';

import {AuthService} from '../core/auth.service';
import {SessionService} from '../core/session.service';
import {NotificationService} from './notification.service';
import {CompanyService} from './company.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard implements CanActivate {

  constructor(private router: Router,
              private companyService: CompanyService,
              private session: SessionService,
              private authService: AuthService,
              private notificationService: NotificationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> {
    const id = route.paramMap.get('company_id');
    return this.session.currentCompanyId$.pipe(
      take(1),
      mergeMap(() => this.session.changeCurrentCompany(id)),
      map((success) => {
        if (!success) {
          this.notificationService.messagesSubj.next('Empresa não existe ou usuário não tem permissão: ' + id);
          return this.router.createUrlTree(['/companies'], {queryParams: {returnUrl: this.router.url}});
        }
        return success;
      })
    );
  }
}
