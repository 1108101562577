import {Department, Location, Manager} from 'src/app/shared/interfaces/core.interface';

import {
  ILocation,
  ILocationGroup
} from 'src/app/shared/interfaces/location.interface';

export interface Details {
  assigned_date: string;
  department: Department;
  exited_date: string;
  labor_union: {
    code: string;
    deleted: boolean;
    id: number;
    name: string;
  };
  location: ILocation;
  location_group: ILocationGroup;
  manager: Manager;
  position: {
    id: string;
    name: string
  };
  salary: number;
  work_shift: string
}

export class Person {
  admission_date: string;
  birthday: string;
  code: string;
  company_id: string;
  cpf: string;
  created_at: string;
  dependents: Dependent[];
  details: Details;
  dismissal_date: string;
  document_id: string;
  document_type: string;
  email: string;
  gender: string;
  id: string;
  name: string;
  social_name: string;
  phone: string;
  updated_at: string;
  status: string;
  active: boolean;

  constructor(p: Person) {
    Object.assign(this, p);
    this.setActive();
  }

  private setActive() {
    if (this.active == null) {
      this.active = !this.dismissal_date;
    }
  }
}

export interface Dependent {
  birthday: string;
  created_at: string;
  id: number;
  name: string;
  registered_by_uid: string;
  relationship: string;
  source: string;
  updated_at: string;
  updated_by_uid: string;
}

export interface DependentPreview {
  text: string;
  detail: string;
}

export interface PersonPayload {
  admission_date: string;
  birthday: string;
  code: string;
  company_id: string;
  cpf: string;
  details: {
    assigned_date?: string;
    exited_date?: string;
    department_id?: number;
    location_id?: number;
    manager_id?: string;
    position_id?: number;
    salary?: number;
    work_shift?: string
  };
  dismissal_date: string;
  document_id: string;
  document_type: string;
  email: string;
  gender: string;
  name: string;
  social_name: string;
  phone: string;
  status: string;
  active: boolean;
}

