import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';

import {SharedModule} from '../../shared/shared.module';

import {ProfileSettingsComponent} from './settings.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIconModule,
    SharedModule
  ],
  declarations: [
    ProfileSettingsComponent
  ],
  exports: [
    ProfileSettingsComponent
  ]
})

export class SettingsDialogModule { }
