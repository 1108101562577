import {SickNoteStatusType} from './sick_note';
import {Person} from './person';
import {
  IV3Location,
  ILocationGroup
} from '../interfaces/location.interface';
import {Manager} from './manager';
import { DetailsComponent } from 'src/app/components/sick-note/details/details.component';
import {Team} from './team';

export interface Restrictions {
  // person_location: {
  //   id: number;
  //   code: string;
  //   name: string;
  //   cnpj: string;
  //   cnae: string;
  // };
  // person_location_group: {
  //   id: number;
  //   name: string;
  // };
  // person_manager: {
  //   id: number;
  //   code: string;
  //   name: string;
  // };
  person_location: boolean;
  person_location_group: boolean;
  person_manager: boolean;
  locations: IV3Location[];
  location_groups: ILocationGroup[];
  managers: Manager[];
  role: {
    code: string;
  };
  team: Team;
}

export interface IUserIDs {
  id: string;
  user_id: string;
}

export class User {
  id: string;
  user_id: string;
  name: string;
  email: string;
  type: string;
  tenant_id: string;
  person?: Person;
  restrictions?: Restrictions;
  created_at: string;
  updated_at: string;
  last_sign_in: string;
  team: Team;

  constructor(user?: User | unknown) {
    if (!!user) {
      this.setUser(user);
    }
    this.user_id = this.id; // TODO ID will change to bigint in the future
  }

  setUser(user: User | any) {
    this.id = user.user_id || user.uid || user.id;
    this.user_id = this.id;
    this.name = user.person?.name || user.name || user.display_name;
    this.email = user.email;
    this.type = user.type;
    this.tenant_id = user.tenant_id;
    this.person = user.person;
    this.restrictions = user.restrictions;
    this.created_at = user.created_at;
    this.updated_at = user.updated_at;
    this.team = user.team;
  }
}

export interface SickNoteStatusPermissions {
  r: SickNoteStatusType[];
  v: SickNoteStatusType[];
  w: SickNoteStatusType[];
}

export interface JWTClaims {
  iam: {
    perm: string[];
    role: {
      company: string;
      tenant: string;
    };
    stat: SickNoteStatusPermissions;
  };
  /**
   * @deprecated Use "iam" instead
   */
  access_policy: Permissions;
}

export class Permissions {
  permissions: {[key: string]: string | boolean};
  roles: {
    company: string;
    tenant: string;
  };
  organization_id: number;
  plan: any;
  sick_note_status: SickNoteStatusPermissions;

  constructor(value?: Permissions) {
    if (!!value) {
      this.permissions = value.permissions || {};
      this.roles.company = value.roles.company || '';
      this.roles.tenant = value.roles.tenant || '';
    } else {
      this.permissions = {};
      this.roles = {
        company: '',
        tenant: ''
      };
    }
  }

  fromJWT(claims: JWTClaims): Permissions {
    if (!!claims.iam) {
      this.permissions = claims?.iam?.perm?.reduce((a, v) => ({...a, [v]: true}), {}) || {};
      this.sick_note_status = claims.iam.stat;
      this.roles = claims.iam.role;
    } else {
      this.permissions = claims.access_policy?.permissions;
      this.sick_note_status = claims.access_policy?.sick_note_status;
      this.roles = claims.access_policy?.roles;
    }
    return this;
  }
}
