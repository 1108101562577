import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  of
} from 'rxjs';

import {
  catchError,
  map
} from 'rxjs/operators';

import {
  ICategory,
  IResponseCategories,
  IType
} from 'src/app/shared/interfaces/types.interfaces';

const API_BASE = '/bff/v1/document-types';

@Injectable()
export class TypesService {
  constructor(private httpClient: HttpClient) {}

  getAllDocumentTypes(): Observable<any> {
    return this.httpClient.get(API_BASE);
  }

  getDocumentTypes(categoryId: number): Observable<IType[]> {
    const params = new HttpParams()
      .set('category_id', categoryId);

    return this.httpClient.get(API_BASE, { params })
      .pipe(
        map(({ categories }: IResponseCategories) => (
          categories.find(({ id }: ICategory) => (
            categoryId === id
          )).types
        )),
        catchError(() => of([]))
      );
  }
}
