import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable, of} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';

import {CompanyService} from './company.service';
import {SessionService} from '../core/session.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultCompanyGuard implements CanActivate {

  constructor(private router: Router,
              private companyService: CompanyService,
              private session: SessionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> {
    return this.session.currentCompanyId$.pipe(
      mergeMap((companyId) => {
        if (!!companyId) {
          return of(this.getUpdatedUrl(state.url, companyId));
        }

        return this.companyService.companies$.pipe(
          take(1),
          map(companies => {
            if (companies?.length === 1) {
              return this.getUpdatedUrl(state.url, companies[0].id);
            }
            return this.router.createUrlTree(['/companies'], { queryParams: { returnUrl: state.url }});
          })
        );
      })
    );
  }

  private getUpdatedUrl(url: string, companyId: string): UrlTree {
    return this.router.parseUrl(url.replace('c/default', `/c/${companyId}`));
  }
}
