export type Type =  'info' | 'warning' | 'critical';
export type Status =  'invalid' | 'valid';

export interface Interface {
  id: string;
  type: Type;
  errors: number;
  created_at: string;
  status?: Status;
  route?: string;
}

export enum TypeEnum {
  info = 'info',
  warning = 'warning',
  critical = 'critical',
}

export enum StatusEnum {
  invalid = 'invalid',
  valid = 'valid',
}

export interface StatusSyncResponse {
  filled: number;
  invalid: number;
  pending: number;
  triage: number;
  valid: number;
  verified: number;
  warning: number;
}