import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyService } from 'src/app/services/company.service';
import { IEditionContent } from 'src/app/components/edition/entities/edition.interfaces';
import {
  IInterval,
  ISituation,
  ISituationAPIResponse
} from 'src/app/components/form-components/entities/form-components.interfaces';
import { FormSituationsService } from 'src/app/components/form-components/situations/situations.service';
import {
  IExtendedSickNote,
  // TODO Enable Rais Reason
  // RaisReason,
  Situation
} from 'src/app/shared/model/sick_note';

export interface ValidateDialogResult {
  changes: IEditionContent;
  message: string;
  action: boolean;
}

export interface ValidateDialogData {
  interval?: IInterval;
  message?: string;
  typeId?: number;
  situations?: ISituation[];

  /**
   * @deprecated
   * Only used in sick-notes-old-form
   * */
  sickNote?: IExtendedSickNote;
  situations$?: Observable<Situation[]>;
}

@Component({
  selector: 'app-validate-dialog',
  templateUrl: './validate-dialog.component.html'
})
export class ValidateDialogComponent implements OnInit {
  form: UntypedFormGroup;
  situationsDataSource: ISituationAPIResponse[];
  multipleAllowed$: Observable<boolean>;
  changes = { values: {} } as IEditionContent;

  static open(
    dialog: MatDialog, data?: ValidateDialogData
  ): MatDialogRef<ValidateDialogComponent, ValidateDialogResult> {
    return dialog.open(ValidateDialogComponent, {
      panelClass: 'simple-dialog',
      width: '700px',
      closeOnNavigation: false,
      maxWidth: 'calc(100vw - 32px)',
      maxHeight: '90%',
      data
    });
  }

  constructor(
    public dialogRef: MatDialogRef<ValidateDialogComponent, ValidateDialogResult>,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private formSituationsService: FormSituationsService,
    @Inject(MAT_DIALOG_DATA) public data: ValidateDialogData
  ) {
    this.form = this.formBuilder.group({
      situations: [ this.data.situations || [] ],
      message: [ this.data.message || '' ]
    });

    this.multipleAllowed$ = this.companyService.settings$
      .pipe(map((s) => !!s?.multiple_situations));
  }

  ngOnInit(): void {
    this.formSituationsService.get(this.data.typeId)
      .subscribe({
        next: (situations: ISituationAPIResponse[]) => {
          this.situationsDataSource = situations || [];
        },
        error: () => this.situationsDataSource = []
      });

    this.situations.valueChanges
      .subscribe((situations) => this.changes.values.situations = situations);
  }

  get situations(): AbstractControl {
    return this.form.get('situations');
  }

  onNoClicked(): void {
    this.dialogRef.close(null);
  }

  onOkClick(): void {
    this.dialogRef.close({
      action: true,
      changes: this.changes,
      message: this.form.get('message').value
    });
  }
}
