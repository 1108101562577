import {
  Component,
  OnInit
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { BaseClass } from 'src/app/core/base-class';
import { SessionService } from 'src/app/core/session.service';

import { IItem } from '../entities/sidebar.interfaces';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-sidebar-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent extends BaseClass implements OnInit {
  companyId: string;
  dashboards: IItem[] = [];

  constructor(
    public sidebarService: SidebarService,
    private sessionService: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.sessionService.currentCompanyId$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((companyId: string) => this.companyId = companyId);
  }
}
