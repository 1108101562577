import {Component, OnInit} from '@angular/core';
import {TitleService} from '../services/title.service';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {filter, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CompanyContainerService} from './company-container.service';

export interface Action {
  id: string;
  type: 'primary' | 'secondary';
  label: string;
  icon: string;
}

@Component({
  selector: 'app-company-container',
  templateUrl: './company-container.component.html',
  styleUrls: ['./company-container.component.scss']
})
export class CompanyContainerComponent implements OnInit {
  title$;
  loading = false;
  actions: Observable<Action[]>;

  constructor(private titleService: TitleService,
              private route: ActivatedRoute,
              private router: Router,
              private service: CompanyContainerService) {
  }

  ngOnInit() {
    this.title$ = this.titleService.title$;
    this.actions = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(this.router),
      map(() => {
        const actions = this.getActions(this.router.routerState, this.router.routerState.root);
        return actions[actions.length - 1];
      }),
    );
  }

  getActions(state, parent): Action[][] {
    const data = [];
    if (parent?.snapshot?.data?.actions) {
      data.push(parent.snapshot.data.actions);
    }
    if (state && parent) {
      data.push(... this.getActions(state, state.firstChild(parent)));
    }
    return data;
  }

  navigate(action: Action): void {
    this.service.clickAction(action);
  }

}
