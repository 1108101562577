<div class="pills">
  <ng-container *ngIf="!pills?.length">
    <ngx-shimmer-loading
      class="pills__pill pills__pill--shimmer"
      *ngFor="let shimmer of [1, 2, 3, 4, 5, 6]"
      [width]="'90px'"
      [height]="'28px'"
    ></ngx-shimmer-loading>
  </ng-container>
  <button
    mat-flat-button
    class="pills__pill"
    *ngFor="let pill of pills; let i = index"
    [ngClass]="{ 'pills__pill--selected': pill.selected }"
    (click)="setPills(i)"
  >{{ pill.name }}</button>
</div>
