<aside
  class="submenu"
  (mouseenter)="sidebarService.hoverItem()"
  (mouseleave)="sidebarService.blurItem()"
>
  <div class="submenu__content">
    <div class="main__scroll submenu__scroll">
      <ul class="submenu__list">
        <ng-container *ngFor="let subItem of sidebarService.hoveredItem?.children">
          <li
            class="submenu__item"
            *ngIf="subItem.allowed"
          >
            <ng-container *ngIf="subItem.routes; else category">
              <app-subitem-button
                class="submenu__subitem"
                [subItem]="subItem"
                [companyId]="companyId"
              ></app-subitem-button>
            </ng-container>
            <ng-template #category>
              <h4 class="submenu__category">
                {{ subItem.title }}
              </h4>
              <ng-container *ngFor="let subSubItem of subItem.children">
                <app-subitem-button
                  class="submenu__subitem"
                  *ngIf="subSubItem.allowed"
                  [subItem]="subSubItem"
                  [companyId]="companyId"
                ></app-subitem-button>
              </ng-container>
            </ng-template>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</aside>
