import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CompanyService} from '../services/company.service';
import {map, take, tap} from 'rxjs/operators';
import {NotificationService} from '../services/notification.service';
import {AuthService} from './auth.service';
import {UserService} from '../services/user.service';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private router: Router,
              private session: SessionService,
              private notificationService: NotificationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const needed = next.data.permissions as string[];
    return this.session.permissions$.pipe(
      take(1),
      map((permissions) => needed.every(p => permissions.permissions[p])),
      tap(allowed => {
        if (!allowed) {
          this.notificationService.messagesSubj.next('Acesso não permitido');
        }
      }),
      map(allowed => allowed || this.router.createUrlTree(['/companies']))
    );
  }
}
