import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular';
import {Transaction} from '@sentry/browser';
import {Integrations} from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', '^https:\\/\\/.+\.web\.app', '^https:\\/\\/.+\.firebaseapp\.com', '^https:\/\/.+\.closecare\.com\.br'],
      routingInstrumentation: Sentry.routingInstrumentation as any
    }) as any,
  ],
  release: `${environment.name}@${environment.version}`,
  environment: environment.production ? 'production' : 'staging',
  tracesSampleRate: 0.3,
});

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

bootstrap().catch(err => console.log('Main.tx', err));

